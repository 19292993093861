<template>
    <div class="header">
        <div class="header_logo">
            <img src="../../../assets/logo_new.png" class="logo">
        </div>

        <div class="header_user" style="margin-left: 20px;margin-right: 30px;" v-if="!token && lang === 'zh'">
            <el-button-group>
                <el-button size="small" @click="toPage('login')" round>登录</el-button>
                <el-button size="small" @click="toPage('register')" round>注册</el-button>
            </el-button-group>
        </div>

        <div class="header_user" style="margin-right: 30px;" v-if="token">
            <el-button
                size="small"
                round
                style="background-image: linear-gradient(135deg, #72BFFA 0%, #1E7DFF 100%);"
                @click="() => this.$router.push('/main')"
            >
                IOT平台
            </el-button>
        </div>

        <div class="header_user" style="margin-left: 20px;margin-right: 30px;" v-if="token">
            <div class="user_name">{{ userInfo.telephone ? userInfo.telephone : userInfo.email }}</div>
            <el-dropdown trigger="click" placement="bottom-start" style="cursor: pointer;" class="header_user"
                         @command="handleUserCommand">
                <span class="el-dropdown-link">
                    <el-avatar :src="userInfo.headImg" style="width: 32px;height: 32px;" v-if="userInfo.headImg"></el-avatar>
                    <el-avatar :src="require('../../../assets/icon_avatar.png')" style="background: transparent;height: 32px;width: 32px" fit="contain" v-else></el-avatar>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-user-solid" command="personalCenter"
                                      v-if="menuData.settingMenuList.length > 0">个人中心
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-switch-button" command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <div class="header_menu">
            <el-select v-model="language" style="width: 120px;" @change="selectLanguage" size="mini" placeholder="请选择语言">
                <img src="@/assets/lang.png" style="width: 14px;height: 14px;margin-top: 7px;margin-left: 4px;" slot="prefix"/>
                <el-option
                    v-for="item in languageList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-popover
                placement="bottom"
                width="150px"
                trigger="hover">
                <el-image
                    style="width: 140px; height: 140px;margin-left: 5px;"
                    :src="require('../../../assets/index/qrCode.png')">
                </el-image>
                <div class="qrcode-text">扫描二维码下载APP</div>
                <div  slot="reference"  v-if="lang === 'zh'">APP下载</div>
            </el-popover>
            <div @click="download()" v-if="lang === 'en'">{{$i18n.t('index.text')}}</div>
            <div @click="openDoc()" v-if="lang === 'zh'">文档</div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import app from "@/main";

export default {

    name: "homeHeader",

    computed: {
        ...mapState('login', ['userMsg', 'menuData']),
        getToken() {
            return this.$token.getToken().accessToken
        }
    },


    watch: {

        //用户信息
        userMsg: {
            handler(newVal) {
                console.log('userMsg', newVal);
                this.userInfo = newVal;
            }
        },

        lang: {
            handler(newVal) {
                console.log('language', newVal);
                this.language = newVal;
            }
        }

    },

    props: {
        lang: {
            Type: String,
            default: ''
        }
    },

    data() {

        return {

            //用户信息
            userInfo: {},

            token: "",

            language: '',

            languageList: [
                {value: 'zh', label: '中文'},
                {value: 'en', label: 'English'},
            ],

        }

    },

    mounted() {
        this.token = window.localStorage.accessToken;
        this.language = this.lang;
        if (this.token) {
            this.getlogininfo();
            //获取用户权限树
            this.getUserAuthorityTree();
        }
    },

    methods: {

        ...mapGetters('login', ['getHomeMenuRedirect', 'getSettingMenuRedirect']),
        ...mapActions('login', ['getlogininfo', 'loginout', 'getUserAuthorityTree']),

        selectLanguage(event) {
            window.localStorage.setItem('language', event);
            this.$router.go(0);
        },

        toPage(type) {
            const routeUrl = this.$router.resolve({path: "/login", query: { type }});
            window.open(routeUrl.href, "_blank");
        },

        /**
         * 打开文档中心
         */
        openDoc() {
            window.open(this.$message.docUrl, '_blank')
        },

        /**
         * 下载APP
         */
        download() {
            window.location.replace("https://funlink.cloud/lgiot/otsgw/ota/app/get")
        },

        handleUserCommand(command) {
            switch (command) {
                case "personalCenter":
                    if (this.getSettingMenuRedirect() !== this.$route.path && this.getSettingMenuRedirect() !== "") {
                        this.$router.push({path: "/setting"});
                    }
                    break;
                case "logout":
                    this.$dialog.showConfirm({
                        content: "是否退出登录？",
                        title: "提示",
                        btn1: "退出",
                        btn2: "取消",
                        icon: 2
                    }, () => {
                        this.loginout();
                    }, () => {
                    })
                    break;
                default:
                    break;
            }
        }

    }

}
</script>

<style scoped>

.header {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}

.logo {
    height: 64px;
    cursor: pointer;
}

.header_logo {
    width: 200px;
    height: 100%;
    float: left;
}

.header_company {
    cursor: pointer;
    float: left;
    color: #000000;
    font-size: 14px;
    margin-top: 24px;
}

.company_list_item_activate {
    background-color: #e8e9ed;
    color: #494d72;
}

.el-dropdown-menu__item {
    margin: 3px 5px;
    border-radius: 3px;
}

.header_user {
    float: right;
    color: #000000;
    line-height: 64px;
}

.el-avatar {
    margin-top: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: right;
}

.el-avatar--icon {
    font-size: 14px;
}

.user_name {
    float: right;
    margin-left: 6px;
    font-size: 14px;
}

.header_menu {
    cursor: pointer;
    float: right;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header_menu div {
    float: left;
    margin-right: 50px;
}

.header_menu div:last-child {
    float: left;
    margin-right: 20px;
}

.header_menu div:hover {
    float: left;
    color: #1890FF;
}

.el-dropdown-link-company {
    background-color: #F7F7F7;
    padding: 8px;
    border-radius: 7px;
}

.el-button-group {
    background-image: linear-gradient(135deg, #72BFFA 0%, #1E7DFF 100%);
    border-radius: 20px;
}

.el-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

.el-button:hover {
    background-color: #abcdff;
}

.qrcode-text {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-align: center;
}

.el-select {
    margin-right: 20px!important;
}

/deep/ .el-input__inner {
    font-size: 14px!important;
}
</style>
