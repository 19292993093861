<template>

    <!-- 过渡页 -->
    <el-container class="full-container">
        <el-header class="shadow">
            <admin-header></admin-header>
        </el-header>
        <el-container class="main-container">
            <el-main>
                <div class="error" v-show="isShow">
                    <div class="container-floud">
                        <div class="col-xs-12 text-center">
                            <div class="container-error-404">
                                <div class="clip">
                                    <div class="shadow">
                                        <span class="digit thirdDigit"></span>
                                    </div>
                                </div>
                                <div class="clip">
                                    <div class="shadow">
                                        <span class="digit secondDigit"></span>
                                    </div>
                                </div>
                                <div class="clip">
                                    <div class="shadow">
                                        <span class="digit firstDigit"></span>
                                    </div>
                                </div>
                                <div class="msg">OH!
                                    <span class="triangle"></span>
                                </div>
                            </div>
                            <h2>很抱歉，你访问的页面找不到了</h2>
                            <p>
                                <a class="tohome" href="">返回首页</a>
                            </p>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>

</template>

<script>
import adminHeader from '@/views/main/components/adminHeader';
import {mapGetters, mapState} from 'vuex';

export default {

    components: {
        adminHeader
    },

    computed: {
        ...mapState('login', ['menuData'])
    },

    watch: {

        //菜单树
        menuData: {
            handler(newVal) {
                if (newVal = []) return;
                this.$router.push({path: this.getHomeMenuRedirect()})
            },
            deep: true
        },

        //监听当前页面路由变化
        '$route.path': {
            handler(newVal) {
                if (newVal === '/') {
                    this.$router.push({path: this.getHomeMenuRedirect()})
                }
            },
            deep: true
        }

    },

    data() {
        return {
            loop1: "",
            loop2: "",
            loop3: "",
            time: 30,
            i: 0,
            isShow: false
        }
    },

    mounted() {
        if (this.$route.path === '/' && this.getHomeMenuRedirect() !== "") {
            this.$router.push({path: this.getHomeMenuRedirect()})
        }

        setTimeout(() => {
            this.isShow = true;
        }, 2000)
        this.loop3 = setInterval(() => {
            if (this.i > 40) {
                clearInterval(this.loop3);
                document.querySelector('.thirdDigit').textContent = 4;
            } else {
                document.querySelector('.thirdDigit').textContent = this.randomNum();
                this.i++;
            }
        }, this.time);
        this.loop2 = setInterval(() => {
            if (this.i > 80) {
                clearInterval(this.loop2);
                document.querySelector('.secondDigit').textContent = 0;
            } else {
                document.querySelector('.secondDigit').textContent = this.randomNum();
                this.i++;
            }
        }, this.time);
        this.loop1 = setInterval( () => {
            if (this.i > 100) {
                clearInterval(this.loop1);
                document.querySelector('.firstDigit').textContent = 4;
            } else {
                document.querySelector('.firstDigit').textContent = this.randomNum();
                this.i++;
            }
        }, this.time);

    },


    destroyed() {
        clearInterval(this.loop1);
        clearInterval(this.loop2);
        clearInterval(this.loop3);
    },

    methods: {
        ...mapGetters('login', ['getHomeMenuRedirect']),
        randomNum() {
            return Math.floor(Math.random() * 9) + 1;
        }
    }

}
</script>

<style scoped>

.full-container {
    height: 100vh;
}

.shadow {
    width: 100%;
    z-index: 20;
    padding: 0px;
    height: 64px !important;
}

.main-container {
    background-color: #E8ECF0;
}

.el-main {
    padding: 10px;
}

.error .clip .shadow {
    height: 180px!important;
}

.error .clip:nth-of-type(2) .shadow {
    width: 130px;
}

.error .clip:nth-of-type(1) .shadow,
.error .clip:nth-of-type(3) .shadow {
    width: 250px;
}

.error .digit {
    width: 150px;
    height: 150px;
    line-height: 150px;
    font-size: 120px;
    font-weight: bold;
}

.error h2 {
    font-size: 32px;
}

.error .msg {
    top: -240px;
    left: 29%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
}

.error span.triangle {
    top: 70%;
    right: 0%;
    border-left: 20px solid #535353;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.error .container-error-404 {
    margin-top: 10%;
    position: relative;
    height: 250px;
    padding-top: 40px;
}

.error .container-error-404 .clip {
    display: inline-block;
    transform: skew(-45deg);
}

.error .clip .shadow {
    overflow: hidden;
}

.error .clip:nth-of-type(2) .shadow {
    overflow: hidden;
    position: relative;
}

.error .clip:nth-of-type(3) .shadow:after,
.error .clip:nth-of-type(1) .shadow:after {
    content: "";
    position: absolute;
    right: -8px;
    bottom: 0px;
    z-index: 9999;
    height: 100%;
    width: 10px;
    background: linear-gradient(90deg, transparent, rgba(173, 173, 173, 0.8), transparent);
    border-radius: 50%;
}

.error .clip:nth-of-type(3) .shadow:after {
    left: -8px;
}

.error .digit {
    position: relative;
    top: 8%;
    color: white;
    background: #07B3F9;
    border-radius: 50%;
    display: inline-block;
    transform: skew(45deg);
}

.error .clip:nth-of-type(2) .digit {
    left: -10%;
}

.error .clip:nth-of-type(1) .digit {
    right: -20%;
}

.error .clip:nth-of-type(3) .digit {
    left: -20%;
}

.error h2 {
    font-size: 24px;
    color: #A2A2A2;
    font-weight: bold;
    padding-bottom: 20px;
}

.error .tohome {
    font-size: 16px;
    color: #07B3F9;
}

.error .msg {
    position: relative;
    z-index: 9999;
    display: block;
    background: #535353;
    color: #A2A2A2;
    border-radius: 50%;
    font-style: italic;
}

.error .triangle {
    position: absolute;
    z-index: 999;
    transform: rotate(45deg);
    content: "";
    width: 0;
    height: 0;
}

@media(max-width: 767px) {
    .error .clip .shadow {
        height: 100px;
    }
    .error .clip:nth-of-type(2) .shadow {
        width: 80px;
    }
    .error .clip:nth-of-type(1) .shadow,
    .error .clip:nth-of-type(3) .shadow {
        width: 100px;
    }
    .error .digit {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 52px;
    }
    .error h2 {
        font-size: 18px;
    }
    .error .msg {
        top: -110px;
        left: 15%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    .error span.triangle {
        top: 70%;
        right: -3%;
        border-left: 10px solid #535353;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
    .error .container-error-404 {
        height: 150px;
    }
}

.col-xs-12 {
    position:relative;
    min-height:1px;
    float:left;
    width:100%;
}

.text-center{
    text-align:center
}

</style>
