import { render, staticRenderFns } from "./resetPasswordDialog.vue?vue&type=template&id=3cc9b691&scoped=true"
import script from "./resetPasswordDialog.vue?vue&type=script&lang=js"
export * from "./resetPasswordDialog.vue?vue&type=script&lang=js"
import style0 from "./resetPasswordDialog.vue?vue&type=style&index=0&id=3cc9b691&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc9b691",
  null
  
)

export default component.exports