<template>
    <el-drawer
        :title="type === 0 ? $i18n.t('empower.text56') : $i18n.t('empower.text33')"
        :visible.sync="drawer"
        :size="400"
        direction="rtl"
        append-to-body
        :wrapperClosable="false"
        @open="handleOpen"
        @close="closeDialog">
        <el-divider></el-divider>

        <!-- 授权申请表单 start -->
        <el-form
            :model="form" size="small"
            :rules="rules"
            ref="ruleForm"
            label-position="top"
            class="empower_form">

            <el-form-item class="is-required">
                <span slot="label">
                    {{$i18n.t('empower.text57')}}
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="$i18n.t('empower.text58')">
                        <i
                            :class="objectIcon"
                            @mouseover="objectIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="objectIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-radio-group v-model="form.object" @change="objectChange">
                    <el-radio :label="item.id" :key="index" v-for="(item, index) in $message.empowerObjectList">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>


            <!--            <el-form-item prop="validityPeriod" label="证书有效期（天）" v-if="form.object === 2">-->
            <!--                <el-input v-model="form.validityPeriod" placeholder="请输入证书有效期"-->
            <!--                          ></el-input>-->
            <!--            </el-form-item>-->

            <el-form-item :label="$i18n.t('empower.text59')" :prop="form.object === 1 ? 'productCode' : 'serverCode'">
                <el-select
                    :popper-append-to-body="false"
                    v-model="form.productCode"
                    :placeholder="$i18n.t('empower.text60')"
                    filterable
                    remote
                    clearable
                    v-show="form.object === 1"
                    :remote-method="getProductMsg"
                    :loading="loadingProduct"
                    @clear="getProductMsg()"
                    @change="productChange">
                    <el-option
                        v-for="item in productList"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code">
                    </el-option>
                </el-select>

                <el-select v-model="form.serverCode" multiple :placeholder="$i18n.t('empower.text61')" @change="serverChange" :popper-append-to-body="false" v-show="form.object === 2">
                    <el-option
                        v-for="item in serverList"
                        :key="item.code"
                        :label="item.objectName"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

<!--            <el-form-item label="授权服务" v-if="form.object === 2" :prop="form.object === 2 ? 'serverCode' : ''">-->
<!--                <el-select v-model="form.serverCode" multiple placeholder="请选择" @change="serverChange">-->
<!--                    <el-option-->
<!--                        v-for="item in serverList"-->
<!--                        :key="item.code"-->
<!--                        :label="item.objectName"-->
<!--                        :value="item.code">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->

            <el-form-item :label="$i18n.t('empower.text62')" :prop="form.object === 1 ? 'objective' : ''" v-if="form.object === 1">
                <el-radio-group v-model="form.objective" @change="selectObjective">
                    <el-radio :label="item.id" :key="index" v-for="(item, index) in $message.empowerObjectiveList"
                              :disabled="isDisabled && item.id === 2">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

<!--            <el-form-item label="是否启用自动计数？" class="is-required" v-if="form.objective === 1">-->
<!--                <el-radio-group v-model="form.auto">-->
<!--                    <el-radio :label="item.id" :key="index" v-for="(item, index) in $message.autoAuthList">-->
<!--                        {{ item.name }}-->
<!--                    </el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->

            <el-form-item :label="$i18n.t('empower.text20')" class="is-required">
                <el-radio-group v-model="form.activeType">
                    <el-radio @change="selectIdentification" :label="item.id" :key="index"
                              :disabled="item.id === 3 && form.objective === 1"
                              v-for="(item, index) in $message.activeTypeList">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

<!--            <el-form-item label="导入设备唯一标识" class="is-required" v-if="form.isFile === 1">-->
<!--                <el-input-->
<!--                    @change="identifyChange"-->
<!--                    v-model.trim="form.identify"-->
<!--                    placeholder="请输入设备唯一标识，多个标识使用‘,’隔开">-->
<!--                </el-input>-->
<!--            </el-form-item>-->

            <el-form-item :label="$i18n.t('empower.text63')" class="is-required" v-if="form.activeType === 1" :error="uniqueKeysErr">
                <div class="title">
                    <el-link type="primary" :href="$message.empowerTemplateUrl"
                             style="font-size: 12px;margin-left: 10px;">{{$i18n.t('empower.text64')}}(Excel)
                    </el-link>
                </div>
                <div class="upload-body"
                     :loading="fileLoading">

                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        :action="$message.uploadEmpowerUrl"
                        :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken,
                            language: language
                        }"
                        drag
                        v-loading="fileLoading"
                        :on-success="onSuccess"
                        :on-error="onError"
                        :on-change="onChange"
                        :before-upload="onbeforeunload"
                        :limit="1"
                        :show-file-list="false"
                        accept=".xlsx,.xls">
                        <div v-if="!file.name" :loading="fileLoading">
                            <i class="el-icon-upload" style="margin-top: 20px;font-size: 40px;"></i>
                            <div class="el-upload__text">{{$i18n.t('empower.text65')}}</div>
                            <div class="el-upload__subtitle">{{$i18n.t('empower.text66')}}.xlsx,.xls</div>
                        </div>
                        <div style="margin-top: 50px;font-size: 20px;color: #3296FA;" v-else>
                            <span>{{ file.name }}</span>
                            <el-button type="text" style="padding: 0px;"
                                       icon="el-icon-delete" @click.stop="delBtn()"></el-button>
                        </div>
                    </el-upload>
                </div>
            </el-form-item>

            <!-- 当授权目的为量产时，申请数量为非必填 -->
            <!-- 当授权目的为调试时，申请数量为必填，且不能高于300 -->
            <el-form-item class="is-required" :error="numberErr">
                <span slot="label">
                    {{$i18n.t('empower.text67')}}
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="$i18n.t('empower.text68')">
                        <i
                            :class="numberIcon"
                            @mouseover="numberIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="numberIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-input-number :disabled="form.activeType === 1" v-model="form.number" style="width: 100%;" :placeholder="$i18n.t('empower.text69')" controls-position="right"></el-input-number>
            </el-form-item>

<!--            <el-form-item label="是否通过License激活？" class="is-required">-->
<!--                <el-radio-group v-model="form.license">-->
<!--                    <el-radio :label="item.id" :key="index" v-for="(item, index) in $message.autoAuthList">-->
<!--                        {{ item.name }}-->
<!--                    </el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->

<!--            <el-form-item-->
<!--                :prop="form.license === 1 ? 'licenseNumber' : ''"-->
<!--                v-if="form.license === 1">-->
<!--                <span slot="label">-->
<!--                    License激活申请数量(个)-->
<!--                </span>-->
<!--                <el-input v-model="form.licenseNumber" placeholder="请输入"></el-input>-->
<!--            </el-form-item>-->

            <el-form-item :label="$i18n.t('empower.text70')" class="is-required" v-if="form.object === 2">
                <el-radio-group v-model="form.allowProbation">
                    <el-radio :label="item.id" :key="index" v-for="(item, index) in $message.autoAuthList">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                v-if="form.object === 2 && form.allowProbation === 1"
                prop="probationPeriod">
                <el-input v-model="form.probationPeriod" :placeholder="$i18n.t('empower.text71')"></el-input>
            </el-form-item>

        </el-form>
        <!-- 授权申请表单 end -->

        <!-- 弹窗底部按钮 start -->
        <div class="demo-drawer__footer">
            <el-button @click="closeDialog" :disabled="fileLoading">{{$i18n.t('empower.text52')}}</el-button>
            <el-button type="primary" :loading="loading" :disabled="fileLoading" @click="submit">{{$i18n.t('empower.text72')}}</el-button>
        </div>
        <!-- 弹窗底部按钮 end -->
    </el-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "applyAuthorizationVouchersDrawer",

    computed: {
        ...mapState('empower', ['applyAuthorizationVouchersDrawerVisible', 'applyAuthorizationVouchersDrawerType', 'applyAuthorizationVouchersMsg'])
    },

    watch: {
        //申请授权凭证抽屉开关
        applyAuthorizationVouchersDrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            },
            deep: true
        },

        applyAuthorizationVouchersDrawerType: {
            handler(newVal) {
                this.type = newVal;
            }
        },

        applyAuthorizationVouchersMsg: {
            handler(newVal) {
                this.msg = newVal;
            }
        },

        // "form.objective": {
        //     handler(newVal) {
        //         if (newVal === 2) {
        //             this.rules.number = [
        //                 {required: true, message: "请输入申请数量", trigger: 'blur'},
        //                 {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'},
        //             ]
        //         } else {
        //             this.rules.number = [
        //                 {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'},
        //             ]
        //         }
        //     }
        // }
    },

    data() {

        return {

            type: 0,

            //抽屉开关
            drawer: false,

            msg: {},

            //申请按钮加载动画开关
            loading: false,

            fileLoading: false,

            language: "",

            objectIcon: "empower_question_alicon iconfont_al icon-yiwen",

            numberIcon: "empower_question_alicon iconfont_al icon-yiwen",

            rules: {
                productCode: [
                    {required: true, message: this.$i18n.t('empower.text73'), trigger: 'change'},
                ],
                serverCode: [
                    {required: true, message: this.$i18n.t('empower.text74'), trigger: 'change'},
                ],
                objective: [
                    {required: true, message: this.$i18n.t('empower.text75')},
                ],
                number: [
                    {required: true, message: this.$i18n.t('empower.text51')},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: this.$i18n.t('empower.text54')},
                    {pattern: /^([0-9]|([0-9][0-9])|([0-9][0-9][0-9])|([0-9][0-9][0-9][0-9])|([0-4][0-9][0-9][0-9][0-9])|50000)$/, message: this.$i18n.t('empower.text76')}
                ],
                licenseNumber: [
                    {required: true, message: this.$i18n.t('empower.text77')},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: this.$i18n.t('empower.text54')},
                ],
                // validityPeriod: [
                //     {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'}
                // ],
                probationPeriod: [
                    {required: true, message: this.$i18n.t('empower.text71')},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: this.$i18n.t('empower.text54')},
                ]
            },

            form: {
                //license申请数量
                // licenseNumber: "",
                //激活方式
                activeType: 2,
                //是否通过设备唯一标识激活
                // isFile: 1,
                //是否启用自动计数
                // auto: 0,
                //是否通过License激活
                license: 1,
                //授权对象
                object: 1,
                //产品code
                productCode: "",
                //服务code
                serverCode: [],
                //授权目的
                objective: 2,
                //申请数量
                number: "",
                //唯一标识
                identify: "",
                uniqueKeys: "",
                objectInfo: [],
                //授权有效天数
                // validityPeriod: "",
                //是否允许试用
                allowProbation: 0,
                //试用天数
                probationPeriod: "",
            },

            //产品列表
            productList: [],

            //服务列表
            serverList: [],

            //唯一标识文件信息
            file: {},

            //是否禁用量产
            isDisabled: false,

            loadingProduct: false,

            uniqueKeysErr: "",

            numberErr: ""

        }

    },

    mounted() {

    },

    methods: {

        ...mapActions('empower', ['closeApplyAuthorizationVouchersDrawer', 'queryAuthorizationBusiness', 'addAuthorization', 'reAddAuthorization']),
        ...mapActions('product', ['queryProduct']),

        /**
         * 授权产品选择回调
         * @param code
         */
        productChange(code) {
            this.isDisabled = false;
            this.productList.forEach(item => {

                if (item.code === code) {
                    this.form.objectInfo = [{
                        objectName: item.name,
                        objectId: item.pid
                    }]
                }
                if (item.code === code && item.status !== 3) {
                    this.isDisabled = true;
                    this.form.objective = 1;
                    this.form.activeType = 2;
                }
            })
        },

        selectObjective(val) {
            //调试情况下申请数量不能超过300
            if (val === 1) {
                this.form.activeType = 2;
                if (!this.form.number) {
                    this.numberErr = this.$i18n.t('empower.text51');
                }else if (this.form.number <= 0) {
                    this.numberErr = this.$i18n.t('empower.text54');
                }else if (this.form.number > 300) {
                    this.numberErr = this.$i18n.t('empower.text78');
                }else {
                    this.numberErr = "";
                }
                // this.rules.number = [
                //     {required: true, message: "请输入申请数量"},
                //     {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'},
                //     {pattern: /^([0-9]|([1-9][0-9])|([1-2][0-9][0-9])|(300))$/, message: '调试目的下，申请数量最多为300'},
                //     {pattern: /^([0-9]|([0-9][0-9])|([0-9][0-9][0-9])|([0-9][0-9][0-9][0-9])|([0-4][0-9][0-9][0-9][0-9])|50000)$/, message: '申请数量最多为50000'},
                // ]
            }else {
                if (!this.form.number) {
                    this.numberErr = this.$i18n.t('empower.text51');
                }else if (this.form.number <= 0) {
                    this.numberErr = this.$i18n.t('empower.text54');
                }else if (this.form.number > 50000) {
                    this.numberErr = this.$i18n.t('empower.text76');
                }else {
                    this.numberErr = "";
                }
                // this.rules.number = [
                //     {required: true, message: "请输入申请数量"},
                //     {pattern: /^([0-9]|([0-9][0-9])|([0-9][0-9][0-9])|([0-9][0-9][0-9][0-9])|([0-4][0-9][0-9][0-9][0-9])|50000)$/, message: '申请数量最多为50000'},
                //     {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'}
                // ]
            }

            return this.numberErr !== "";
        },

        async getProductMsg(val) {
            this.loadingProduct = true;
            await this.queryProduct({
                currPage: 1,
                pageSize: 10000,
                keyword: val
            }).then(res => {
                this.productList = res.result.list ? res.result.list : [];
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() =>
                this.loadingProduct = false);
        },

        /**
         * 是否通过设备唯一标识激活选择监听
         * @param val
         */
        selectIdentification(val) {
            this.form.uniqueKeys = "";
            this.form.identify = "";
            this.form.number = "";
            this.file = {}
            this.$refs.upload.clearFiles();
        },

        /**
         * 导入设备唯一标识失去焦点监听
         * @param val
         */
        identifyChange(val) {
            let identifyNum = val === "" ? 0 : val.split(',').length;
            if (val.substring(val.length - 1, val.length) === ',') {
                identifyNum = identifyNum - 1;
                this.form.identify = this.form.identify.substring(0, val.length - 1)
            }
            let uniqueKeysNum = this.form.uniqueKeys === "" ? 0 : this.form.uniqueKeys.split(',').length;
            if (this.form.uniqueKeys.substring(this.form.uniqueKeys.length - 1, this.form.uniqueKeys.length) === ',') {
                uniqueKeysNum = uniqueKeysNum - 1;
                this.form.uniqueKeys = this.form.uniqueKeys.substring(0, this.form.uniqueKeys.length - 1)
            }

            let identify = this.form.identify.split(',');
            let identifyList = new Set();
            identify.forEach(item => identifyList.add(item));
            identify = this.$config.deepCopy(Array.from(identifyList));

            let uniqueKeys = this.form.uniqueKeys.split(',');
            uniqueKeys.forEach(item => {
                Array.from(identifyList).forEach((val, index) => {
                    if (item === val) {
                        identify.splice(index, 1);
                    }
                })
            })
            this.form.identify = identify.join(',');

            // //当前选择为服务时不需要强制申请数量
            // if (this.form.object === 2) return;
            // this.form.number = identifyNum + uniqueKeysNum;
        },

        /**
         * 请选择授权对象值变更监听
         */
        objectChange(val) {
            this.$nextTick(() => {
                try {
                    this.$refs.ruleForm.resetFields();
                }catch (e) {}
                this.form.uniqueKeys = "";
                this.form.identify = "";
                this.form.number = "";
                this.file = {}
                this.form.serverCode = [];
                this.form.productCode = "";
                console.log(this.form.serverCode)
                this.uniqueKeysErr = "";
                this.numberErr = "";
                if (val === 1) {
                    this.isDisabled = false;
                    this.form.objective = 1;
                }
            })
        },

        /**
         * 授权服务选择监听
         * @param val
         */
        serverChange(val) {
            let list = [];
            val.forEach(item => {
                this.serverList.forEach(v => {
                    if (item === v.code) {
                        list.push({
                            authorizationBusinessCode: item,
                            objectName: v.objectName,
                            objectId: v.objectId
                        });
                    }
                })
            });
            this.form.objectInfo = list;
        },

        /**
         * 提交申请
         */
        submit() {
            let isStop = false;

            //调试情况下申请数量不能超过300
            // if (this.form.objective === 1 && this.form.number > 300) {
            //     this.$dialog.showMessage("调试目的下，申请数量最多为300", this.$config.TOAST_WARNING);
            //     isStop = true;
            // } else {
            //     this.rules.number = [
            //         {required: true, message: "请输入申请数量"},
            //         {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'}
            //     ]
            // }

            isStop = this.selectObjective(this.form.objective)

            console.log(this.rules.number)

            if (this.form.activeType === 1 && this.form.uniqueKeys === "" && this.form.identify === "") {
                // this.$dialog.showMessage("请上传设备唯一标识", this.$config.TOAST_WARNING);
                this.uniqueKeysErr = this.$i18n.t('empower.text79');
                isStop = true;
            }else {
                this.uniqueKeysErr = ""
            }

            if (this.form.activeType === 1 && this.form.identify !== "") {
                let uniqueKeys = this.form.identify.split(',');
                uniqueKeys.forEach(item => {
                    console.log(item)
                    if (!item) {
                        // this.$dialog.showMessage("唯一标识中有空标识，请仔细检查", this.$config.TOAST_WARNING);
                        this.uniqueKeysErr = this.$i18n.t('empower.text80');
                        isStop = true;
                    }
                })
            }

            this.$refs.ruleForm.validate((valid) => {
                if (valid && !isStop) {
                    this.loading = true;
                    // let licenseAmount = this.form.activeType === 3 ? this.form.number : null;
                    let objectInfo = JSON.stringify(this.form.objectInfo);
                    let uniqueKeys =
                        (this.form.uniqueKeys ? this.form.uniqueKeys : "") +
                        ((this.form.uniqueKeys && this.form.identify) ? ',' : '') +
                        (this.form.identify ? this.form.identify : "");
                    if (this.type === 1) {
                        this.reAddAuthorization({
                            code: this.msg.code,
                            authorizationSwitch: 1,
                            autoAuthorizationSwitch: this.form.activeType === 3 ? 1 : 0,
                            // licenseAmount,
                            objectInfo,
                            activeType: this.form.activeType,
                            objective: this.form.object === 1 ? this.form.objective : null,
                            totalAuthorization: this.form.number,
                            type: this.form.object,
                            allowProbation: this.form.object === 2 ? this.form.allowProbation === 1 ? "Y" : "N" : null,
                            probationPeriod: this.form.object === 2 ? this.form.probationPeriod : null,
                            // validityPeriod: this.form.object === 2 ? this.form.validityPeriod : null,
                            uniqueKeys
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('empower.text81'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                            this.$emit('getMsg');
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                        return;
                    }
                    this.addAuthorization({
                        authorizationSwitch: 1,
                        autoAuthorizationSwitch: this.form.activeType === 3 ? 1 : 0,
                        // licenseAmount,
                        objectInfo,
                        activeType: this.form.activeType,
                        objective: this.form.object === 1 ? this.form.objective : null,
                        totalAuthorization: this.form.number,
                        type: this.form.object,
                        allowProbation: this.form.object === 2 ? this.form.allowProbation === 1 ? "Y" : "N" : null,
                        probationPeriod: this.form.object === 2 ? this.form.probationPeriod : null,
                        // validityPeriod: this.form.object === 2 ? this.form.validityPeriod : null,
                        uniqueKeys
                    }).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('empower.text81'), this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getMsg');
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        },

        async handleOpen() {
            //获取产品列表
            await this.getProductMsg();

            //获取服务列表
            await this.queryAuthorizationBusiness({
                currPage: 1,
                pageSize: 10000,
            }).then(res => {
                this.serverList = res.result.list ? res.result.list : [];
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })

            if (this.type === 1) {
                this.form = {
                    //license申请数量
                    // licenseNumber: this.msg.licenseAmount,
                    //是否通过设备唯一标识激活
                    // isFile: 1,
                    //是否启用自动计数
                    // auto: this.msg.autoAuthorizationSwitch,
                    //是否通过License激活
                    // license: 1,
                    //激活方式
                    activeType: this.msg.activeType,
                    //授权对象
                    object: this.msg.type,
                    //产品code
                    productCode: this.msg.type === 1 ? this.getProductCode() : "",
                    //服务code
                    serverCode: this.msg.type === 2 ? this.getServerCode() : "",
                    //授权目的
                    objective: this.msg.objective ? this.msg.objective : null,
                    //申请数量
                    number: this.msg.activeType === 1 ? 0 : this.msg.totalAuthorization,
                    //唯一标识
                    identify: "",
                    uniqueKeys: "",
                    objectInfo: JSON.parse(this.msg.objectInfo),
                    //授权有效天数
                    // validityPeriod: "",
                    //是否允许实用
                    allowProbation: this.msg.allowProbation === "Y" ? 1 : 0,
                    //试用天数
                    probationPeriod: this.msg.probationPeriod ? this.msg.probationPeriod : ""
                }
            }
        },

        /**
         * 获取产品code
         * @returns {string}
         */
        getProductCode() {
            let msg = JSON.parse(this.msg.objectInfo)
            let code = "";
            this.productList.forEach(item => {
                if (item.pid === msg[0].objectId) {
                    code = item.code;
                }
            })
            return code
        },

        /**
         * 获取服务code
         * @returns {*[]}
         */
        getServerCode() {
            let msg = JSON.parse(this.msg.objectInfo)
            let codeList = [];
            msg.forEach(item => {
                codeList.push(item.authorizationBusinessCode);
            })
            return codeList;
        },

        closeDialog() {
            this.form = {
                //license申请数量
                // licenseNumber: "",
                //是否通过设备唯一标识激活
                // isFile: 1,
                //是否启用自动计数
                // auto: 0,
                //是否通过License激活
                // license: 1,
                //激活方式
                activeType: 2,
                //授权对象
                object: 1,
                //产品code
                productCode: "",
                //服务code
                serverCode: [],
                //授权目的
                objective: 2,
                //申请数量
                number: "",
                //唯一标识
                identify: "",
                uniqueKeys: "",
                objectInfo: [],
                //授权有效天数
                // validityPeriod: "",
                //是否允许实验
                allowProbation: 0,
                //试用天数
                probationPeriod: "",
            };
            this.file = {};
            this.closeApplyAuthorizationVouchersDrawer();
            try {
                this.$refs.ruleForm.resetFields();
            }catch (e) {}
            this.uniqueKeysErr = "";
            this.numberErr = "";
        },

        delBtn() {
            this.form.number = "";
            this.file = {}
            this.$refs.upload.clearFiles();
            this.form.uniqueKeys = ""
        },

        onChange(file) {
        },

        onbeforeunload(file) {
            this.fileLoading = true;
        },

        onSuccess(response, file, fileList) {
            this.fileLoading = false;

            console.log(this.fileLoading)

            if (response.success) {
                if (response.result === "") {
                    this.delBtn();
                    // this.$dialog.showMessage("excel表中没有数据！", this.$config.TOAST_WARNING);
                    this.uniqueKeysErr = this.$i18n.t('empower.text82')
                    return;
                }
                this.file = file;

                this.uniqueKeysErr = ""
                let uniqueKeys = response.result.split(',');
                let identify = this.form.identify.split(',');
                this.$config.deepCopy(uniqueKeys).forEach((item, index) => {
                    identify.forEach((val) => {
                        if (val === item) {
                            uniqueKeys.splice(index, 1);
                        }
                    })
                })
                this.form.uniqueKeys = uniqueKeys.join(',');

                this.file.name = file.name;
                let keys = (this.form.uniqueKeys + (this.form.identify ? ',' + this.form.identify : '')).split(',');
                let keysList = new Set();
                keys.forEach(item => keysList.add(item))

                //当前选择为服务时不需要强制申请数量
                // if (this.form.object === 2) return;
                this.form.number = Array.from(keysList).length;
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        onError(err, file, fileList) {
            this.fileLoading = false;
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        }

    }

}
</script>

<style scoped>
/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px;
    margin-bottom: 0px;
}

/deep/ .el-drawer__body {
    padding: 0 10px;
}

/deep/ .el-divider--horizontal {
    margin: 0;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.el-form {
    margin-top: 10px;
}

.el-select, .el-radio-group {
    width: 100%;
}

.el-radio {
    margin-top: 10px;
}

/deep/ .el-form-item__label {
    padding-bottom: 0px;
}

.title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.90);
}


.upload-body {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    height: 150px;
    border-radius: 4px;
}

.upload-demo {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

/deep/ .el-upload-dragger, /deep/ .el-upload {
    width: 100%;
    height: 100%;
}

.el-upload__text {
    line-height: 5px;
    margin-top: -10px;
}

.el-upload__subtitle {
    font-size: 12px;
    line-height: 30px;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;
    width: 400px;
    text-align: left;
    background-color: white;
}

.empower_question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.empower_question_alicon {
    color: #3296FA;
    font-size: 16px;
    position: relative;
    top: 1px;
    cursor: pointer;
}

.empower_form {
    margin-bottom: 80px;
}
</style>
