<template>
    <el-container class="full-container">

        <!-- 头部栏 start -->
        <el-header class="shadow">
            <admin-header></admin-header>
        </el-header>
        <!-- 头部栏 end -->

        <!-- 主体页面 start -->
        <el-container class="main-container">
            <!-- 左侧菜单 start -->
            <div class="notice_left">
                <div
                    v-for="(item, index) in typeList"
                    :key="index" :class="'notice_left_item ' + (activeName == item.id ? 'notice_left_item_action' : '')"
                    @click="selectItem(item)">
                    <i :class="'iconfont ' + item.icon"></i>
                    {{ item.name }}
                </div>
            </div>
            <!-- 左侧菜单 end -->

            <!-- 右侧通知表格 start -->
            <el-main>
                <!-- 标题 start -->
                <div class="page_title">
                    {{ title }}
                </div>
                <!-- 标题 end -->

                <!-- 卡片 start -->
                <el-card
                    :body-style="{
                        textAlign: 'left',
                        padding: '15px',
                        height: '100%'
                    }"
                    shadow="never">
                    <el-button
                        size="mini"
                        plain
                        type="primary"
                        @click="markRead"
                        style="float: right; padding: 5px;margin-bottom: 10px;">
                        {{ $i18n.t('notice.text1') }}
                    </el-button>
                    <el-table
                        ref="noticeTable"
                        class="notice_table"
                        :data="noticeMsg.list"
                        :height="height - 50"
                        :row-style="{cursor: 'pointer'}"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        v-loading="loading"
                        @row-click="toNoticeMsg"
                        style="width: calc(100%);float: left">
                        <el-table-column
                            :selectable='selectEnable'
                            type="selection"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('notice.text2')">
                            <template slot-scope="scope">
                                {{ $config.getMsgItemUtil(typeList, scope.row.type, 'id', 'name') }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('notice.text3')">
                            <template slot-scope="scope">
                                {{ scope.row.userName ? scope.row.userName : "-" }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('notice.text4')"
                            prop="title">
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('notice.text5')"
                            prop="createTime">
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('notice.text6')">
                            <template slot-scope="scope">
                                {{ scope.row.isRead ? $i18n.t('notice.text7') : $i18n.t('notice.text8') }}
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination
                        background
                        :current-page="currPage"
                        @current-change="handleCurrentChange"
                        :page-size="10"
                        layout=" prev, pager, next"
                        style="float: right;margin-top: 10px;"
                        :total="parseInt(noticeMsg.totalRecords)">
                    </el-pagination>
                </el-card>
                <!-- 卡片 end -->
            </el-main>
            <!-- 右侧通知表格 end -->
        </el-container>
        <!-- 主体页面 end -->

        <!-- 通知详情抽屉 -->
        <notice-msg></notice-msg>
    </el-container>
</template>

<script>

import adminHeader from '@/views/main/components/adminHeader';
import noticeMsg from '@/views/main/components/noticeMsg';
import {mapActions} from "vuex";

export default {

    components: {
        adminHeader,
        noticeMsg
    },

    name: "notice",

    data() {

        return {
            //左侧当前选择菜单
            activeName: '-1',

            //标题内容
            title: this.$i18n.t('notice.text9'),

            //表格高度
            height: 200,

            loading: false,

            //公告通知信息
            noticeMsg: {},

            currPage: 1,

            //左侧菜单列表
            typeList: [
                {id: '-1', name: this.$i18n.t('notice.text9'), icon: "icon-quanbugonggao"},
                {id: '0', name: this.$i18n.t('notice.text10'), icon: "icon-gengxin"},
                {id: '1', name: this.$i18n.t('notice.text11'), icon: "icon-xiaoxi"}
            ]

        }

    },

    mounted() {

        //获取并设置当前菜单
        this.activeName = this.$route.query.id

        //获取并设置菜单id所对应标题
        this.typeList.forEach(item => {
            if (item.id === this.activeName) {
                this.title = item.name;
            }
        })
        setTimeout(() => {
            this.height = (window.innerHeight - 45 - 160);
        }, 100);
        window.addEventListener('resize', this.setHeight);

        //获取通知列表
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    methods: {

        ...mapActions('main', ['queryNotice', 'queryReadNotice', 'addReadNotice']),

        /**
         * 设置高度
         */
        setHeight() {
            this.height = (window.innerHeight - 45 - 160);
        },

        /**
         * 打开公告详情抽屉
         * @param row
         */
        toNoticeMsg(row) {
            this.setReadNotice([row.code])
            this.$store.commit("main/SET_NOTICE_MSG", row);
            this.$store.commit("main/SET_NOTICE_MSG_DIALOG_VISIBLE", true);
        },

        /**
         * 获取通知列表
         */
        getMsg() {
            this.loading = true;
            this.queryNotice({
                currPage: this.currPage,
                pageSize: 10,
                type: this.activeName >= 0 ? this.activeName : null
            }).then(res => {
                let noticeMsg = res.result;
                this.getNotReadList(noticeMsg);
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        /**
         * 已读信息的多选框禁用
         * @param row
         * @param rowIndex
         * @returns {boolean}
         */
        selectEnable(row, rowIndex) {
            return !row.isRead;
        },

        /**
         * 设置已读
         */
        markRead() {
            let arr = this.$refs.noticeTable.selection;
            console.log(arr);
            let list = [];
            arr.forEach(item => {
                list.push(item.code)
            })
            if (list.length === 0) {
                this.$dialog.showMessage(this.$i18n.t('notice.text12'), this.$config.TOAST_WARNING);
                return;
            }
            this.setReadNotice(list);
        },

        /**
         * 设置未读为已读
         * @param codeList
         */
        setReadNotice(codeList) {
            this.addReadNotice({
                code: codeList.join(',')
            }).then(res => {
                this.getMsg();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取未读列表
         * @param noticeMsg
         */
        getNotReadList(noticeMsg) {
            let list = [];
            noticeMsg.list.forEach(item => {
                item.isRead = false;
                list.push(item.code);
            })
            if (list.length === 0) {
                this.noticeMsg = [];
                return;
            }
            this.queryReadNotice({
                code: list.join(',')
            }).then(res => {
                res.result.forEach(item => {
                    noticeMsg.list.forEach(val => {
                        if (item.noticeCode === val.code) {
                            val.isRead = true;
                            console.log(item.noticeCode)
                        }
                    })
                })
                this.noticeMsg = noticeMsg
                console.log(this.noticeMsg.list)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换左侧菜单回调
         * @param item
         */
        selectItem(item) {
            this.$config.changeURLArg('id', item.id)
            this.activeName = parseInt(item.id);
            this.title = item.name;
            this.currPage = 1;
            this.getMsg();
        },

        /**
         * 点击分页器回调
         * @param page
         */
        handleCurrentChange(page) {
            this.currPage = page;
            this.getMsg()
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }

    }

}
</script>

<style scoped>
.full-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.notice_left {
    width: 232px;
    background-color: white;
    padding: 6px 5px;
    box-shadow: 0 5px 4px 0 #E1E1E1;
    z-index: 9;
    text-align: left;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.iconfont {
    color: inherit !important;
    margin-right: 6px;
}

.notice_left_item {
    width: calc(100% - 30px);
    height: 40px;
    color: #333333;
    font-size: 16px;
    text-align: left;
    line-height: 40px;
    margin: 5px;
    cursor: pointer;
    padding-left: 20px;
    font-weight: 500;
}

.notice_left_item_action {
    background-color: #1890FF;
    color: #ffffff;
    border-radius: 3px;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    height: calc(100% - 85px);
}

.el-main {
    width: calc(100% - 200px);
    height: 100%;
    padding: 0px;
}

.shadow {
    width: 100%;
    z-index: 20;
    padding: 0;
    position: fixed;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    height: 64px !important;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

/deep/ .el-card__body {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

.main-container {
    background-color: #E8ECF0;
    height: calc(100% - 84px);
    margin-top: 64px;
}


.tabs {
    width: 130px;
    float: left;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
