<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            应用调试
        </div>
        <!-- 标题 end -->

        <!-- 卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px'
            }"
            shadow="never">

            <el-row>

                <!-- 属性操作 start -->
                <el-col :span="6" class="col" :style="{height: height + 'px'}">
                    <div class="header_btn">
                        <div class="title">产品名称: {{ productName }}</div>
                    </div>
                    <el-divider></el-divider>

                    <div :style="{
                                overflowY: 'auto',
                                height: (height - 45) + 'px'
                            }">
                        <el-row v-for="(item, index) in attributeList" :key="index" class="item">
                            <el-col :span="8" class="attr_name">
                                {{ item.attrName }} ：
                            </el-col>
                            <el-col :span="16">

                                <el-input v-if="item.type === 'String' || item.type === 'Raw'" type="textarea"
                                          v-model.trim="item.value" placeholder="" size="mini"></el-input>
                                <el-input-number v-if="item.type === 'Integer'" v-model="item.value"
                                                 :min="item.min" :step="item.step" :max="item.max"
                                                 size="mini"></el-input-number>
                                <el-input-number v-if="item.type === 'Float'" v-model="item.value"
                                                 :min="item.min" :step="item.step" :max="item.max"
                                                 :precision="7" size="mini"></el-input-number>
                                <el-select v-if="item.type === 'Boolean'" v-model="item.value" :popper-append-to-body="false" placeholder="请选择"
                                           size="mini">
                                    <el-option label="true" :value="true"></el-option>
                                    <el-option label="false" :value="false"></el-option>
                                </el-select>
                                <el-select v-if="item.type === 'Enum' || item.type === 'Fault'"
                                           :popper-append-to-body="false"
                                           v-model="item.value" placeholder="请选择" size="mini">
                                    <el-option
                                        v-for="item1 in item.list"
                                        :key="item1.id"
                                        :label="item1.name"
                                        :value="item1.id">
                                    </el-option>
                                </el-select>

                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <!-- 属性操作 end -->

                <!-- 上报按钮 start -->
                <el-col :span="2" :style="{height: height + 'px',textAlign: 'center'}">
                    <div :style="{marginTop: ((height/2)-30) + 'px'}">
                        <i class="el-icon-sort" style="transform: rotate(90deg);font-size: 20px;"></i>
                        <br>
                        <el-button :loading="loading" size="mini" type="primary" style="margin-top: 10px;"
                                   @click="submit">上报1
                        </el-button>
                    </div>
                </el-col>
                <!-- 上报按钮 end -->

                <!-- 日志信息 start -->
                <el-col :span="16" class="col" :style="{height: height + 'px'}">
                    <div class="header_btn">
                        <div class="title">通讯日志</div>
                    </div>
                    <el-divider></el-divider>

                    <el-table
                        ref="firmwareTable"
                        class="firmware_table"
                        :data="logList"
                        :height="(height - 55)"
                        size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        style="margin: 10px;width: calc(100% - 20px);">
                        <el-table-column
                            label="时间"
                            prop="time"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            label="内容"
                            prop="value">
                        </el-table-column>
                    </el-table>
                </el-col>
                <!-- 日志信息 end -->

            </el-row>

        </el-card>
        <!-- 卡片 end -->

    </div>
</template>

<script>
import adminHeader from '@/views/main/components/adminHeader';
// import mqtt from 'mqtt'
// import RFS from '../../utils/RFS'
import MQTT from "../../utils/mqtt";
import {mapState, mapActions} from "vuex";

export default {

    components: {
        adminHeader
    },

    name: "applicationDebug",

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                this.productName = newVal.name
            },
            deep: true
        }
    },

    data() {

        return {

            //卡片高度
            height: 100,

            //产品名称
            productName: "",

            //属性列表
            attributeList: [],

            //服务key列表
            serviceKeyList: [],

            //日志数据
            logList: [],

            //mqtt对象
            mqtt: null,

            //mqtt发送信息内容（上报内容）
            sendMsg: "",

            //上报按钮加载动画开关
            loading: false

        }

    },

    mounted() {
        //获取产品信息
        this.getProductDetail({
            productCode: this.$route.query.id,
            isRefresh: true
        });

        //判断密钥是否存在，存在则连接MQTT服务
        if (this.$route.query.secret) {
            this.mqtt = new MQTT({
                deviceSecret: this.$route.query.secret,
                mac: this.$route.query.mac,
                pid: this.$route.query.pid,
                clientId: this.$route.query.clientId
            }, (isSuccess, msg) => {
                if (isSuccess) {
                    this.logList.unshift({
                        time: this.$config.format(new Date().getTime(), true),
                        value: isSuccess ? "连接成功" : "连接失败"
                    });
                    this.mqtt.mqttReceive(this.onMQTTMsg, [
                        // 上报属性应答主题
                        'c_rptattr/+/',
                        // 服务心跳主题
                        'heart/#',
                        // 服务端启动推送期望
                        'c_desired/',
                        // APP实时推送期望值
                        'setattr/',
                        // 设备绑定
                        'device_binding/+/'
                    ])
                }
            });
        }

        //缩放兼容
        setTimeout(() => {
            this.height = (window.innerHeight - 45 - 153);
        }, 100)
        window.addEventListener('resize', this.setHeight);

        //获取产品服务与属性
        this.getMsg();
    },

    methods: {

        ...mapActions('product', ['getProductDetail']),
        ...mapActions('effect', ['queryProductServiceList']),

        /**
         * 更改高度
         */
        setHeight() {
            this.height = (window.innerHeight - 45 - 153);
        },

        /**
         * MQTT消息监听
         * @param isSendResponse 是否是响应回调，非响应回调则为请求（发送）回调
         * @param msg 回调消息内容
         */
        onMQTTMsg(isSendResponse, msg) {
            this.loading = false;

            //如果为响应回调则获取回调内容中的时间字段并格式化，如果为请求回调则使用当前时间字段
            let time = isSendResponse ? this.$config.format(JSON.parse(msg).t, true) : this.$config.format(new Date().getTime(), true);

            //将信息查入日志头部
            this.logList.unshift({
                time: time,
                value: isSendResponse ? msg : msg + '\n' + this.sendMsg
            });

            //如果为响应消息则将消息回填到左边属性列表表单中
            if (isSendResponse) this.setAttrMsg(msg);
            console.log(isSendResponse ? '响应 =》 ' : '上报结果 =》 ', msg);
        },

        /**
         * 回填属性信息
         * @param msg 属性信息
         */
        setAttrMsg(msg) {
            let data = JSON.parse(msg);
            data.attrs.forEach(item => {
                this.attributeList.forEach(item2 => {
                    if (item.k === item2.attrKey) {
                        item2.value = item.v
                    }
                })
            })
        },

        /**
         * 上报消息
         */
        submit() {
            let list = [];

            //遍历服务列表创建消息框架 标准消息格式为：{"attrs":[{"k":"attr02","v":2.3},{"k":"attr01","v":9}],"k":"test"}
            this.serviceKeyList.forEach(item => {
                list.push({
                    k: item,
                    attrs: []
                })
            });

            //深拷贝属性列表
            let data = this.$config.deepCopy(this.attributeList);
            //遍历属性列表，将属性加入到对应服务列表中
            for (let i = 0; i < data.length; i++) {
                //判断左边属性是否填写了信息，填写了的话则直接加入服务列表中
                if ((data[i].value + "") !== "" && (data[i].value + "") !== 'undefined' && data[i].value != null) {
                    list.forEach(item => {
                        if (item.k === data[i].serviceKey) {
                            item.attrs.push({
                                k: data[i].attrKey,
                                v: data[i].value
                            })
                        }
                    })
                }
            }

            //去掉拼接完数据中的空属性的服务
            this.$config.deepCopy(list).forEach((item, index) => {
                if (item.attrs.length <= 0) {
                    list.splice(index, 1);
                }
            });
            this.sendMsg = JSON.stringify(list);
            this.loading = true;
            //上报属性信息
            this.mqtt.messageSends(JSON.stringify(list));
        },

        /**
         * 获取产品服务与属性
         */
        getMsg() {
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (res.result && res.result.length > 0) this.getAttrList(res.result);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取并格式化属性信息
         * @param data
         */
        getAttrList(data) {
            //属性列表
            let attrList = [];
            //服务列表（数据是以属性方式返回所以说会有重复的服务key，所以用Set方式去重）
            let serviceList = new Set();

            for (let i = 0; i < data.length; i++) {
                if (data[i].productAttributeList && data[i].productAttributeList.length > 0) {
                    data[i].productAttributeList.forEach(item => {
                        let attr = {
                            //属性名称
                            attrName: item.name,
                            //属性Key
                            attrKey: item.attributeKey,
                            //属性格式
                            type: this.$config.getMsgItemUtil(this.$message.formatIDList, item.dataType, 'id', 'name'),
                            //属性内容
                            value: undefined,
                            //最大值
                            max: item.maxIntValue ? item.maxIntValue : undefined,
                            //最小值
                            min: item.minIntValue ? item.minIntValue : undefined,
                            //步长
                            step: item.stepIntValue ? item.stepIntValue : undefined,
                            //属性对应的服务key
                            serviceKey: data[i].serviceKey
                        };
                        //将服务key加入数组
                        serviceList.add(data[i].serviceKey);
                        //属性要是为枚举或故障型时则遍历其数据列表
                        if (item.dataType === 3 || item.dataType === 7) {
                            let list = [];
                            let enumList = JSON.parse(item.enumValues);
                            for (let j = 0; j < enumList.length; j++) {
                                for (let key in enumList[j]) {
                                    list.push({
                                        id: key,
                                        name: enumList[j][key]
                                    })
                                }
                            }
                            attr.list = list;
                        }
                        attrList.push(attr)
                    })
                } else {
                    continue;
                }
            }
            this.attributeList = attrList;
            this.serviceKeyList = Array.from(serviceList);
            console.log(attrList);
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }
    },

    destroyed() {
        //注销resize监听
        window.removeEventListener('resize', this.setHeight);
        //关闭MQTT
        this.mqtt.closeMQTT();
    }

}
</script>

<style scoped>
.el-card {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    margin: 64px 24px 24px 24px;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-card__body {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

.el-main {
    padding: 18px !important;
    height: 100%;
}

.main-container {
    background-color: #F0F2F5;
    height: calc(100% - 84px);
    margin-top: 48px;
}

.el-card {
    border: none;
    height: 100%;
    border-radius: 8px;
}

.item {
    padding: 10px;
}

/deep/ .el-divider--horizontal {
    margin: 4px 0;
}

.col {
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
}

.header_btn {
    width: calc(100% - 16px);
    height: 30px;
    padding: 0 8px 5px 8px;
}

.title {
    float: left;
    margin-top: 8px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
}

.attr_name {
    font-weight: 400;
    font-size: 14px;
    color: #595959;
    text-align: right;
    padding-right: 10px;
}

.el-select {
    width: 100%;
}

/deep/ .el-input-number {
    width: 100%;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
