<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            {{$i18n.t('debug.text1')}}
<!--            <el-popover-->
<!--                placement="top-start"-->
<!--                title=""-->
<!--                width="200"-->
<!--                trigger="hover"-->
<!--                content="若您还未开发完固件时,可用虚拟设备来调试您的应用;若您已开发完固件未开发应用时,可采取真机设备来调试您的设备">-->
<!--                <div-->
<!--                    @mouseover="headerIcon = 'question_coicon icon-yuanyin'"-->
<!--                    @mouseleave="headerIcon = 'question_alicon iconfont_al icon-yiwen'"-->
<!--                    style="cursor: pointer;margin-top: 0;float: right;margin-right: 10px;"-->
<!--                    slot="reference">-->
<!--                    <span class="debug_notes">如何进行调试</span>-->
<!--                    <i :class="headerIcon"></i>-->
<!--                </div>-->

<!--            </el-popover>-->

            <el-link
                type="primary"
                :href="$message.debugDocUrl"
                target="_blank"
                style="cursor: pointer;margin-top: 0;float: right;margin-right: 20px;">
                {{$i18n.t('debug.text2')}}
            </el-link>
        </div>
        <!-- 标题 end -->

        <!-- 卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <div>

                <!-- 头部产品详情 start -->
                <div class="product_msg" v-if="productList.length > 0">
                    <el-image :src="productData.productImageUrl" class="product_img">
                        <div slot="error">
                            <div class="image_slot">
                                {{$i18n.t('debug.text3')}}
                            </div>
                        </div>
                    </el-image>
                    <div class="product_msg_list">
                        <div class="product_msg_top">
                            <div class="product_name">{{ productData.productName }}</div>

                            <el-dropdown size="small" @command="handleCommand" trigger="click">
                                <el-button class="product_edit" size="mini" plain type="primary">
                                    {{$i18n.t('debug.text4')}}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu :append-to-body="false" slot="dropdown">
                                    <el-dropdown-item
                                        v-for="(item, index) in productList"
                                        :command="item"
                                        :class="currentProduct.code === item.code ? 'company_list_item_activate':''"
                                        :disabled="currentProduct.code === item.code"
                                        :key="index">
                                        {{ item.name }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                        </div>
                        <div class="product_msg_bottom">
                            <div class="product_msg_item">PID：{{ productData.pid }}</div>
                            <div class="product_msg_item">{{$i18n.t('debug.text5')}}{{ productData.categoryName }}</div>
                        </div>
                    </div>
                </div>

                <div class="product_msg" v-else>
                    <el-empty :description="$i18n.t('debug.text6')" :image-size="40"></el-empty>
                </div>
                <!-- 头部产品详情 end -->

                <!-- 真机设备列表 start -->
                <div class="table1">

                    <div class="header_btn">
                        <div class="title">
                            {{$i18n.t('debug.text7')}}
                            <el-popover
                                placement="top-start"
                                title=""
                                width="200"
                                trigger="hover"
                                :content="$i18n.t('debug.text8')">

                                <i
                                    :class="contentIcon"
                                    @mouseover="contentIcon = 'question_coicon icon-yuanyin'"
                                    @mouseleave="contentIcon = 'question_alicon iconfont_al icon-yiwen'"
                                    slot="reference"></i>

                            </el-popover>
                        </div>
                    </div>

                    <el-table
                        ref="realProductTable"
                        class="real_product_table"
                        :data="realProductMsg.list"
                        size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        v-loading="realLoading"
                        :style="{
                            width: '100%',
                            minHeight: (productList.length > 0 && realProductMsg.list && realProductMsg.list.length > 0 ? 'auto' : (0 + 'px')),
                            height: (productList.length > 0 && realProductMsg.list && realProductMsg.list.length > 0 ? 'auto' : (36 + 'px')),
                        }">
                        <el-table-column
                            prop="deviceId"
                            :label="$i18n.t('debug.text9')">
                        </el-table-column>
                        <el-table-column
                            prop="telephone"
                            :label="$i18n.t('debug.text10')">
                        </el-table-column>
                        <el-table-column
                            :label="$i18n.t('debug.text11')">

                            <template slot-scope="scope">
                                <span class="status off-line" v-if="scope.row.isOnline === 'N'">{{$i18n.t('debug.text12')}}</span>
                                <span class="status on-line" v-else>{{$i18n.t('debug.text13')}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            width="150"
                            :label="$i18n.t('debug.text15')">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toDeviceDebug(scope.row)">{{$i18n.t('debug.text14')}}
                                </el-button>
                                <el-button type="text" style="color:#FF2825;" size="small" @click="delReal(scope.row)">
                                    {{$i18n.t('debug.text16')}}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        v-if="productList.length > 0 && realProductMsg.list.length > 0"
                        small
                        background
                        :current-page="realPage"
                        @current-change="handleRealCurrentChange"
                        :page-size="10"
                        layout=" prev, pager, next"
                        style="margin-top: 10px;text-align: right;"
                        :total="parseInt(realProductMsg.totalRecords)">
                    </el-pagination>


                    <el-empty :description="$i18n.t('debug.text17')" :image-size="60" v-else></el-empty>
                </div>
                <!-- 真机设备列表 end -->

                <!-- 虚拟设备列表 start -->
                <div class="table1">
                    <div class="header_btn">
                        <div class="title">{{$i18n.t('debug.text18')}}</div>
                        <el-button plain class="btn" size="small" type="primary" icon="el-icon-plus"
                                   @click="dialogQRCodeVisible = true"
                                   v-if="productList.length > 0">{{$i18n.t('debug.text19')}}
                        </el-button>
                    </div>


                    <el-table
                        ref="fakeProductTable"
                        :class="productList.length > 0 ? '' : 'fake_product_table'"
                        :data="fakeProductMsg.list"
                        size="mini"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        v-loading="fakeLoading"
                        :style="{
                            width: '100%',
                            minHeight: (productList.length > 0 && fakeProductMsg.list && fakeProductMsg.list.length > 0 ? 'auto' : (0 + 'px')),
                            height: (productList.length > 0 && fakeProductMsg.list && fakeProductMsg.list.length > 0 ? 'auto' : (36 + 'px')),
                        }">
                        <el-table-column
                            prop="deviceId"
                            :label="$i18n.t('debug.text9')">
                        </el-table-column>
                        <el-table-column
                            prop="telephone"
                            :label="$i18n.t('debug.text10')">
                        </el-table-column>
                        <el-table-column
                            width="200"
                            :label="$i18n.t('debug.text15')">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="toPanelDebug(scope.row)">
                                    {{ $i18n.t('debug.text20') }}
                                </el-button>
                                <el-button type="text" style="color:#FF2825;" size="small"
                                           @click="delFake(scope.row)">{{ $i18n.t('debug.text16') }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination
                        v-if="productList.length > 0 && fakeProductMsg.list.length > 0"
                        small
                        background
                        :current-page="fakePage"
                        @current-change="handleFakeCurrentChange"
                        :page-size="10"
                        layout=" prev, pager, next"
                        style="margin-top: 10px;text-align: right;"
                        :total="parseInt(fakeProductMsg.totalRecords)">
                    </el-pagination>

                    <el-empty :description="$i18n.t('debug.text17')" :image-size="60" v-else></el-empty>
                </div>
                <!-- 虚拟设备列表 end -->

            </div>

        </el-card>
        <!-- 卡片 end -->



        <!-- 新增二维码弹窗 start -->
        <el-dialog
            :title="$i18n.t('debug.text19')"
            width="302px"
            @close="hidePopover"
            @opened="getVirtualBindCode()"
            :visible.sync="dialogQRCodeVisible">
            <div>
                <p class="dialog_text">{{$i18n.t('debug.text21')}}</p>
                <p class="dialog_text">{{$i18n.t('debug.text22')}}</p>
                <div style="width: 100%;">
                    <div ref="QRCodeUrl" class="QRCodeUrl"></div>
                </div>
            </div>
        </el-dialog>
        <!-- 新增二维码弹窗 end -->

    </div>

</template>

<script>

import adminHeader from '@/views/main/components/adminHeader';
import {mapState, mapActions} from "vuex";
import RFS from '../../utils/RFS';
import QRCode from "qrcodejs2";

export default {

    components: {
        adminHeader
    },

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                this.productData = {
                    productName: newVal.name,
                    pid: newVal.pid,
                    productImageUrl: newVal.icon,
                    categoryName: newVal.categoryName ? (this.language === 'zh' ? JSON.parse(newVal.categoryName).zh : JSON.parse(newVal.categoryName).en) : ""
                }
            },
            deep: true
        }
    },

    data() {

        return {

            //二维码弹窗开关
            dialogQRCodeVisible: false,

            //产品详情
            productData: {
                //产品名称
                productName: "",
                pid: "",
                //产品拟物图
                productImageUrl: "",
                //品类名称
                categoryName: ""
            },
            //icon样式
            headerIcon: "question_alicon iconfont_al icon-yiwen",
            contentIcon: "question_alicon iconfont_al icon-yiwen",
            //表格高度
            tableHeight: 50,
            //加载动画开关
            realLoading: false,
            fakeLoading: false,
            //表格数据
            realProductMsg: {
                list: [],
                totalRecords: 0
            },
            fakeProductMsg: {
                list: [],
                totalRecords: 0
            },
            key: 'lgKey',
            //轮循开关
            loopTime: null,
            fakePage: 1,
            realPage: 1,
            productList: [],
            currentProduct: {},
            language: localStorage.getItem('language')
        }

    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = (window.innerHeight - 45 - 452 - 8) / 2;
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.getProductMsg();
    },

    methods: {

        ...mapActions('product', ['queryProduct', 'getProductDetail', 'queryDevice', 'delVirtualDevice', 'unBindPlatformDevice']),
        ...mapActions('device', ['queryDebugDevicePager', 'queryVirtualDevicePager', 'generateVirtualBindCode', 'isVirtualBindCodeAlive', 'deleteDevice', 'checkDeviceExist']),

        /**
         * 获取产品列表
         */
        getProductMsg() {
            this.queryProduct({
                currPage: 1,
                pageSize: 10000,
            }).then(async res => {
                this.productList = res.result.list ? res.result.list : [];

                if (!res.result.list && res.result.list.length <= 0) return;
                if (this.$route.query.id) {
                    res.result.list.forEach(item => {
                        if (this.$route.query.id === item.code) {
                            this.currentProduct = item;
                        }
                    })
                } else {
                    this.currentProduct = res.result.list[0];
                    this.$config.changeURLArg('id', res.result.list[0].code);
                }
                await this.getProductDetail({
                    productCode: this.currentProduct.code,
                    isRefresh: true
                });
                this.getFakeMsg();
                this.getRealMsg();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换产品回调
         * @param command
         */
        async handleCommand(command) {
            this.$config.changeURLArg('id', command.code);
            this.currentProduct = command;
            await this.getProductDetail({
                productCode: command.code,
                isRefresh: true
            });
            this.getFakeMsg();
            this.getRealMsg();
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = (window.innerHeight - 45 - 452 - 8) / 2;
        },

        /**
         * 获取虚拟设备列表
         */
        getFakeMsg() {
            this.fakeLoading = true;
            this.queryVirtualDevicePager({
                currPage: this.fakePage,
                pageSize: 10,
                pid: this.productData.pid
            }).then(res => {
                this.fakeProductMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.fakeLoading = false;
            })
        },

        /**
         * 获取真实设备列表
         */
        getRealMsg() {
            this.realLoading = true;
            this.queryDebugDevicePager({
                currPage: this.realPage,
                pageSize: 10,
                pid: this.productData.pid
            }).then(res => {
                this.realProductMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.realLoading = false;
            })
        },

        /**
         * 虚拟设备分页点击监听
         * @param page 点击页数
         */
        handleFakeCurrentChange(page) {
            this.fakePage = page;
            this.getFakeMsg();
        },

        /**
         * 真实设备分页点击监听
         * @param page 点击页数
         */
        handleRealCurrentChange(page) {
            this.realPage = page;
            this.getRealMsg();
        },

        /**
         * 删除虚拟设备
         * @param row 设备详情
         */
        delFake(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('debug.text23'),
                title: this.$i18n.t('debug.text24'),
                btn1: this.$i18n.t('debug.text25'),
                btn2: this.$i18n.t('debug.text26'),
                icon: 2
            }, () => {
                this.deleteDevice({
                    deviceId: row.deviceId
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('debug.text27'), this.$config.TOAST_SUCCESS);
                    this.getFakeMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },

        /**
         * 删除真实设备
         * @param row 设备详情
         */
        delReal(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('debug.text28'),
                title: this.$i18n.t('debug.text24'),
                btn1: this.$i18n.t('debug.text25'),
                btn2: this.$i18n.t('debug.text26'),
                icon: 2
            }, () => {
                this.deleteDevice({
                    deviceId: row.deviceId
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('debug.text27'), this.$config.TOAST_SUCCESS);
                    this.getRealMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },

        /**
         * 跳转真实设备调试
         * @param row
         */
        toDeviceDebug(row) {
            window.localStorage.setItem('deviceId', row.deviceId);
            this.$router.push({
                path: "/deviceDebug", query: {
                    id: this.currentProduct.code,
                    secret: row.secret,
                    mac: row.deviceName,
                    deviceId: row.deviceId,
                    code: row.code,
                    pid: this.productData.pid,
                    telephone: row.telephone
                }
            });
        },

        /**
         * 跳转虚拟设备调试
         * @param row
         */
        toPanelDebug(row) {
            this.checkDeviceExist({
                deviceId: row.deviceId
            }).then(res => {
                window.localStorage.setItem('deviceId', row.deviceId);
                this.$router.push({
                    path: "/applicationDebug",
                    query: {
                        id: this.currentProduct.code,
                        secret: row.secret,
                        mac: row.deviceName,
                        deviceId: row.deviceId,
                        pid: this.productData.pid,
                        clientId: row.clientId,
                        telephone: row.telephone
                    }
                });
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                this.getFakeMsg();
            })
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        getVirtualBindCode() {
            this.generateVirtualBindCode({
                pid: this.productData.pid
            }).then(res => {
                this.addFakeDevice(res.result.code)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 打开新增虚拟设备弹窗
         */
        addFakeDevice(code) {
            let dom = this.$refs["QRCodeUrl"];
            let father = document.getElementsByClassName("QRCodeUrl")[0];
            let childrens = father.children;
            for (let i = 0; i < childrens.length; i++) {
                father.removeChild(father.lastChild);
            }
            setTimeout(() => {
                // let url = this.$config.url;
                let API_HOST = window.location.protocol+"//"+window.location.host+"/"
                let url = API_HOST + "#/download"
                let msg = this.$config.assembleURLArg(url, 'code', code);
                console.log(msg)
                new QRCode(dom, {
                    text: msg,
                    width: 262,
                    height: 262,
                    colorDark: "black",
                    colorLight: "white"
                });
            });

            //轮循获取手机端扫码回调
            if (!this.loopTime) {
                this.loopTime = setInterval(() => {
                    this.isVirtualBindCodeAlive({
                        bindcode: code
                    }).then(res => {
                        if (res.result.status === 1) {
                            this.$router.push({
                                path: "/applicationDebug",
                                query: {
                                    id: this.currentProduct.code,
                                    secret: res.result.deviceSecret,
                                    mac: res.result.mac,
                                    deviceId: res.result.deviceId,
                                    clientId: res.result.clientId,
                                    pid: this.productData.pid,
                                    telephone: res.result.telephone
                                }
                            });
                        }
                    }, err => {
                        console.log(err);
                    })
                }, 3000)
            }
        },

        /**
         * 隐藏添加二维码弹窗；结束请求轮循
         */
        hidePopover() {
            if (this.loopTime) {
                this.dialogQRCodeVisible = false;
                clearInterval(this.loopTime);
                this.loopTime = null;
            }
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight)
        this.hidePopover();
    }
}

</script>

<style scoped>

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.product_msg .el-empty {
    padding: 0px!important;
}

.product_msg /deep/ .el-empty__description {
    margin-top: 10px!important;
}

.product_msg /deep/ .el-empty__description p {
    font-size: 12px!important;
}

.product_edit {
    float: left;
    margin-left: 21px;
    height: 22px;
    padding: 3px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-card__body {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

.main-container {
    background-color: #E8ECF0;
    margin-top: 48px;
    height: calc(100% - 84px);
}

.el-main {
    padding: 18px !important;
    height: 100%;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 64px 24px 24px 24px;
}


.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_btn {
    width: 100%;
    height: 30px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title {
    float: left;
    margin-top: 8px;
    font-weight: bold;
}

.real_product_table, .fake_product_table {
    margin-top: 15px;
}

.QRCodeUrl {
    width: 262px;
}

.debug_notes {
    color: #3296FA;
    font-size: 14px;
    font-weight: 100;
}

.product_msg {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    position: relative;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 20px;
}

.product_msg_list {
    height: 60px;
    margin: 10px 0;
    float: left;
}

.product_msg_top {
    height: 22px;
    padding: 7px 0;
}

.product_msg_bottom {
    height: 22px;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.company_list_item_activate {
    background-color: #e8e9ed;
    color: #494d72;
}

.product_msg_item {
    float: left;
    font-size: 10px;
    color: #595959;
    font-weight: 400;
    margin-left: 40px;
}

.product_msg_item:first-child {
    margin-left: 0;
}

.table1 {
    width: calc(100% - 20px);
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 10px;
    margin-top: 16px;
}

.el-table {
    width: calc(100% - 20px);
}

.dialog_text {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 14px;
    color: #595959;
}

/deep/ .cell {
    padding: 0 !important;
}

.question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}


.status {
    width: auto;
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
}

.off-line {
    border: 1px solid #757575;
    color: #757575;
}

.on-line {
    border: 1px solid #389E0D;
    color: #389E0D;
}

/deep/ .el-dropdown-menu {
    position: relative!important;
    left: 0!important;
    top: 22px!important;
    z-index: 6!important;
}

/deep/ .el-dropdown-menu__item {
    white-space: nowrap;
}

/deep/ .el-dropdown-menu__item:first-child {
    margin-top: 4px!important;
}
</style>
