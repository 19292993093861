<template>
    <div v-if="isWeChat">
        <img src="../../assets/wechat_mask_ios.jpg" class="mask"  v-if="isIOS"/>
        <img src="../../assets/wechat_mask_android.png" class="mask"  v-else/>
    </div>
</template>

<script>
export default {

    name: "download",

    computed:{
        isWeChat:function(){
            let ua = navigator.userAgent.toLowerCase();
            let isWeChat =  ua.match(/MicroMessenger/i) == 'micromessenger';
            if(isWeChat){
                document.getElementsByTagName("body")[0].style = "width:100%;height:100%;margin:0px;padding:0px;";
                document.getElementById("app").style = "width:100%;height:100%;";
            }
            return isWeChat
        },
        isIOS:function(){
            return /(iPhone|iPad|iPod|IOS)/i.test(navigator.userAgent);
        }
    },

    data() {
        return {
            iosAPPUrl: "https://funlink.cloud/lgiot/otsgw/ota/app/get?type=ios",
            androidAPPUrl: "https://funlink.cloud/lgiot/otsgw/ota/app/get"
        }
    },

    mounted() {
        if (!this.isWeChat) {
            window.location.replace(this.isIOS ? this.iosAPPUrl : this.androidAPPUrl);
        }
    }

}
</script>

<style scoped>

.mask{
    width: 100%;
    height: 100%;
}
</style>
