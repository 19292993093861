<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="重置密码"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <!-- 重置密码表单 start -->
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">

            <el-form-item prop="areaNumber">
                <el-select v-model="form.areaNumber" style="width: 100%;" placeholder="请选择国家">
                    <el-option
                        v-for="item in areaList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="tel">
                <el-input
                    type="text"
                    v-model.trim="form.tel"
                    :placeholder="'请输入' + usernameTitle">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="code">
                <el-input
                    v-model.trim="form.code"
                    placeholder="验证码">
                </el-input>
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                    :type="isShowPwd1 ? 'text' : 'password'"
                    v-model.trim="form.password"
                    @input="pwdChange"
                    placeholder="输入密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd1 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd1 = !isShowPwd1"></i>
                </el-input>
            </el-form-item>

            <el-form-item prop="confirmPassword" :error="pwdErr">
                <el-input
                    :type="isShowPwd2 ? 'text' : 'password'"
                    v-model.trim="form.confirmPassword"
                    placeholder="确认密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd2 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd2 = !isShowPwd2"></i>
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 重置密码表单 end -->

        <!-- 弹窗底部按钮 start -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
        </span>
        <!-- 弹窗底部按钮 end -->

    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['resetPasswordDialogVisible'])
    },

    watch: {
        //重置密码弹窗开关
        resetPasswordDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        systemConfig: {
            handler(newVal) {
                this.usernameTitle =
                    (newVal.supportTelephone ? '手机号' : '') +
                    (newVal.supportTelephone && newVal.supportEmail ? '/' : '') +
                    (newVal.supportEmail ? '邮箱' : '')
            },
            deep: true
        },
        areaNumberList: {
            handler(newVal) {
                this.areaList = newVal;
                this.form.areaNumber = this.areaList[0].value
            },
            deep: true
        }
    },

    props: {
        dataCenters: {
            Type: String,
            default: "CN"
        },
        language: {
            Type: String,
            default: "zh"
        },
        systemConfig: {
            Type: Object,
            default: () => ({
                "supportTelephone": true,
                "supportWechat": false,
                "supportMicroApp": false,
                "supportApple": false,
                "supportEmail": false,
                "supportGzh": false
            })
        },
        areaNumberList: {
            Type: Array,
            default: []
        }
    },
    data() {

        //再次输入密码验证
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
                // password 是表单上绑定的字段
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }

        let validateEmailOrPhone = (rule, value, callback) => {
            const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^1[0-9]{10}$/;
            if (this.systemConfig.supportTelephone && this.systemConfig.supportEmail) {
                if (!value) {
                    callback(new Error("请输入邮箱或手机号"));
                    return;
                } else if (!emailRegex.test(value) && !phoneRegex.test(value)) {
                    callback(new Error("请输入有效的邮箱或手机号"));
                    return;
                }
            } else if (this.systemConfig.supportTelephone) {
                if (!value) {
                    callback(new Error("请输入手机号"));
                    return;
                } else if (!phoneRegex.test(value)) {
                    callback(new Error("请输入有效的手机号"));
                    return;
                }
            } else if (this.systemConfig.supportEmail) {
                if (!value) {
                    callback(new Error("请输入邮箱"));
                    return;
                } else if (!emailRegex.test(value)) {
                    callback(new Error("请输入有效的邮箱"));
                    return;
                }
            }
            callback();
        };

        return {

            //密码显示开关
            isShowPwd1: false,

            //再次密码显示开关
            isShowPwd2: false,

            //修改按钮加载动画开关
            loading: false,

            form: {
                //验证码
                code: "",
                //手机号
                tel: "",
                smsId: "",
                password: "",
                confirmPassword: "",
                areaNumber: ""
            },

            //验证码按钮加载动画开关
            codeBtnLoading: false,

            //验证码重新获取倒计时时间（秒）
            time: 60,

            //手机号输入框禁用/启用开关
            isCodeBtnDisabled: false,

            //验证码按钮文案
            codeBtnMsg: "获取验证码",

            //弹窗按钮
            visible: false,

            rules: {
                tel: [
                    {required: true, validator: validateEmailOrPhone }
                ],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
                password: [
                    {required: true, message: "请输入密码", trigger: "blur"},
                    {pattern: /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^])[A-Za-z\d@$!%*?&~#^]{8,}$/, message: "必须包含大小写字母、数字、特殊字符、长度不小于8"}
                ],
                confirmPassword: [{required: true, validator: validatePass2}],
            },

            pwdErr: "",

            usernameTitle: '',

            areaList: []

        }

    },

    methods: {

        ...mapActions('login', ['closeResetPasswordDialog']),
        ...mapActions('login', ['sendSms', 'restPassword']),

        openDialog() {},

        pwdChange(val) {
            if (val === this.form.confirmPassword) {
                this.pwdErr = ""
            } else {
                this.pwdErr = "两次输入密码不一致!"
            }
        },

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.closeResetPasswordDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                code: "",
                tel: "",
                smsId: "",
                password: "",
                confirmPassword: ""
            }
        },

        /**
         * 获取验证码
         */
        getCode() {

            const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^1[0-9]{10}$/;
            if (this.systemConfig.supportTelephone && this.systemConfig.supportEmail) {
                if (!this.form.tel) {
                    return;
                } else if (!emailRegex.test(this.form.tel) && !phoneRegex.test(this.form.tel)) {
                    return;
                }
            } else if (this.systemConfig.supportTelephone) {
                if (!this.form.tel) {
                    return;
                } else if (!phoneRegex.test(this.form.tel)) {
                    return;
                }
            } else if (this.systemConfig.supportEmail) {
                if (!this.form.tel) {
                    return;
                } else if (!emailRegex.test(this.form.tel)) {
                    return;
                }
            }

            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.form.tel,
                type: '3',
                areaNumber: this.form.areaNumber
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        /**
         * 发送完成验证码后的倒计时处理
         */
        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        },

        /**
         * 提交重置密码表单
         */
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.restPassword({
                        phoneCode: this.form.tel,
                        code: this.form.code,
                        password: this.$md5(this.form.password),
                        smsId: this.form.smsId,
                        areaNumber: this.form.areaNumber
                    }).then(res => {
                        this.$dialog.showMessage("重置成功", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                    }, err => {
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        }

    }

}
</script>

<style scoped>
.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}
</style>
