<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="固件发布"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="升级方式：" prop="upgradeType">
                <el-select v-model="form.upgradeType" :popper-append-to-body="false" clearable placeholder="请选择">
                    <el-option
                        v-for="item in $message.upgradeTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="升级区域：" prop="region">
                <el-select v-model="form.region" :popper-append-to-body="false" clearable placeholder="请选择">
                    <el-option
                        v-for="item in $message.regionList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="发布方式：" required>
                <el-radio-group v-model="form.releaseMode">
                    <el-radio
                        v-for="item in $message.releaseModeList"
                        :label="item.id">
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="按数量：" prop="number" v-if="form.releaseMode === 1">
                <el-input
                    type="text"
                    v-model.trim="form.number"
                    placeholder="请输入">
                </el-input>
            </el-form-item>

            <el-form-item label="按比例：" prop="proportion" v-if="form.releaseMode === 1">
                <el-select v-model="form.proportion" :popper-append-to-body="false" clearable placeholder="请选择">
                    <el-option
                        v-for="item in $message.proportionList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "releaseFirmwareDialog",

    computed: {
        ...mapState('maintain', ['releaseFirmwareDialogVisible'])
    },

    watch: {

        releaseFirmwareDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            form: {
                upgradeType: "",
                region: "",
                releaseMode: 0,
                number: "",
                proportion: ""
            },

            rules: {
                upgradeType: [
                    {required: true, message: "请选择升级方式", trigger: "blur"}
                ],
                region: [
                    {required: true, message: "请选择升级区域", trigger: "blur"}
                ],
                number: [
                    {required: true, message: "请输入设备数量", trigger: "blur"}
                ],
                proportion: [
                    {required: true, message: "请选择比例", trigger: "blur"}
                ]
            }

        }

    },

    methods: {

        ...mapActions('maintain', ['closeReleaseFirmwareDialog']),

        openDialog() {},

        closeDialog() {
            this.closeReleaseFirmwareDialog();
        },



    }

}
</script>

<style scoped>
.el-select {
    width: 100%;
}
</style>
