<template>
    <div>
        <div class="page_title">
            OTA升级
        </div>

        <!-- 头部产品详情 start -->
        <div class="product_msg">
            <el-image :src="productData.productImageUrl" class="product_img">
                <div slot="error">
                    <div class="image_slot">
                        没有产品拟物图
                    </div>
                </div>
            </el-image>
            <div class="product_msg_list">
                <div class="product_msg_top">
                    <div class="product_name">{{ productData.productName }}</div>

                    <el-dropdown size="small" @command="handleCommand" trigger="click">
                        <el-button class="product_edit" size="mini" plain type="primary">
                            切换产品<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(item, index) in productList"
                                :command="item"
                                :class="currentProduct.code === item.code ? 'company_list_item_activate':''"
                                :disabled="currentProduct.code === item.code"
                                :key="index">
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                </div>
                <div class="product_msg_bottom">
                    <div class="product_msg_item">PID：{{ productData.pid }}</div>
                    <div class="product_msg_item">品类：{{ productData.categoryName }}</div>
                </div>
            </div>
        </div>
        <!-- 头部产品详情 end -->

        <!-- 升级列表卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '0',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <el-form :inline="true" size="small" :model="form">

                <el-form-item>
                    <el-input v-model.trim="form.keyword" clearable placeholder="固件名称" @clear="getMsg(1)"
                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.upgradeType" clearable placeholder="升级方式">
                        <el-option
                            v-for="item in $message.upgradeTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.region" clearable placeholder="地区">
                        <el-option
                            v-for="item in $message.regionList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="addFirmwareDialog">
                        新增固件
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="$store.commit('maintain/SET_RELEASE_FIRMWARE_DIALOG_VISIBLE', true)">
                        固件发布
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="$router.push({path: '/firmwareTest'})">
                        测试
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="$router.push({path: '/firmwareMsg'})">
                        详情
                    </el-button>
                </el-form-item>

            </el-form>



            <el-table
                ref="otaTable"
                class="ota_table"
                :data="otaMsg.list"
                :height="cardHeight - 75 - 16"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">

                <el-table-column
                    label="固件版本">
                </el-table-column>

                <el-table-column
                    label="更新日志">
                </el-table-column>

                <el-table-column
                    label="升级方式">
                </el-table-column>

                <el-table-column
                    label="升级地区">
                </el-table-column>

                <el-table-column
                    label="创建时间">
                </el-table-column>

                <el-table-column
                    label="状态">
                </el-table-column>

                <el-table-column
                    width="150"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small">测试
                        </el-button>
                        <el-button type="text" size="small">
                            发布
                        </el-button>
                        <el-button type="text" icon="el-icon-more"></el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(otaMsg.totalRecords)">
            </el-pagination>

        </el-card>
        <!-- 升级列表卡片 end -->

        <!-- 上传固件包弹窗 -->
        <add-firmware-dialog @getMsg="getMsg"></add-firmware-dialog>

        <!-- 固件发布弹窗 -->
        <release-firmware-dialog @getMsg="getMsg"></release-firmware-dialog>

    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddFirmwareDialog from "@/views/product/views/firmware/components/dialog/addFirmwareDialog";
import ReleaseFirmwareDialog from "@/views/maintain/components/dialog/releaseFirmwareDialog";

export default {

    name: "ota",

    components: {ReleaseFirmwareDialog, AddFirmwareDialog},

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                this.productData = {
                    productName: newVal.name,
                    pid: newVal.pid,
                    productImageUrl: newVal.icon,
                    categoryName: newVal.categoryName ? JSON.parse(newVal.categoryName).zh : ""
                }
            },
            deep: true
        }
    },

    data() {

        return {

            //产品详情
            productData: {
                //产品名称
                productName: "",
                pid: "",
                //产品拟物图
                productImageUrl: "",
                //品类名称
                categoryName: ""
            },

            loading: false,

            otaMsg: {
                list: [],
                totalRecords: 10
            },

            form: {
                //固件名称
                keyword: "",
                //升级类型
                upgradeType: "",
                //地区
                region: ""
            },

            productList: [],

            //卡片高度
            cardHeight: 300,

            currentProduct: {}

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.getProductMsg();
    },

    methods: {

        ...mapActions('product', ['queryProduct', 'getProductDetail']),

        getMsg(page) {

        },

        handleCurrentChange(page) {},

        addFirmwareDialog() {
            this.$store.commit('firmware/SET_FIRMWARE_DIALOG_TYPE', 3);
            this.$store.commit('firmware/SET_ADD_FIRMWARE_DIALOG_VISIBLE', true);
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 168 - 64 - 24 - 32;
        },

        /**
         * 获取产品列表
         */
        getProductMsg() {
            this.queryProduct({
                currPage: 1,
                pageSize: 10000,
            }).then(res => {
                this.productList = res.result.list ? res.result.list : [];

                if (!res.result.list && res.result.list.length <= 0) return;
                if (this.$route.query.id) {
                    res.result.list.forEach(item => {
                        if (this.$route.query.id === item.code) {
                            this.currentProduct = item;
                        }
                    })
                } else {
                    this.currentProduct = res.result.list[0];
                }
                this.getProductDetail({
                    productCode: this.currentProduct.code,
                    isRefresh: true
                });
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换产品回调
         * @param command
         */
        handleCommand(command) {
            this.$config.changeURLArg('id', command.code);
            this.currentProduct = command;
            this.getProductDetail({
                productCode: command.code,
                isRefresh: true
            });
        },

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    }

}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    margin: 0 24px;
    padding: 16px;
}

.product_msg {
    height: 80px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
}


.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}


.product_msg_list {
    height: 60px;
    margin: 10px 0;
    float: left;
    text-align: left;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 20px;
}

.product_msg_top {
    height: 22px;
    padding: 7px 0;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.product_edit {
    float: left;
    margin-left: 21px;
    height: 22px;
    padding: 3px;
}

.company_list_item_activate {
     background-color: #e8e9ed;
     color: #494d72;
 }

.product_msg_item {
    float: left;
    font-size: 10px;
    color: #595959;
    font-weight: 400;
    margin-left: 40px;
}

.product_msg_item:first-child {
    margin-left: 0;
}

.product_msg_bottom {
    height: 22px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}
</style>
