<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            设备调试
        </div>
        <!-- 标题 end -->

        <!-- 卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <el-row>

                <!-- 属性表 start -->
                <el-col :span="8" class="col" :style="{height: height + 'px'}">
                    <el-dropdown placement="bottom" trigger="click" @command="handleCommand">
                        <div class="header_btn">
                            <el-row :class="isConnect ? 'device_card' : 'device_card_close'">
                                <el-col :span="18" class="device_id" :title="deviceId">
                                    {{ deviceId }}
                                </el-col>
                                <el-col :span="6">
                                    <div :class="isConnect ? 'state' : 'state_close'">
                                        {{ isConnect ? '在线' : '离线' }}
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </div>
                                    <div :class="isConnect ? 'spot' : 'spot_close'"></div>
                                </el-col>
                            </el-row>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in deviceList" :key="item.deviceId"
                                              :command="item" :disabled="item.isOnline === 'N'">
                                <div style="float: left;">{{ item.deviceId }}</div>
                                <div style="float: right;">
                                    <div :class="item.isOnline === 'Y' ? 'state' : 'state_close'">
                                        {{ item.isOnline === 'Y' ? '在线' : '离线' }}
                                    </div>
                                    <div :class="item.isOnline === 'Y' ? 'spot' : 'spot_close'"></div>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-divider></el-divider>
                    <el-table
                        ref="attributeTable"
                        class="attribute_table"
                        :data="attributeList"
                        :height="height - 73"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        style="width: calc(100%)">
                        <el-table-column
                            label="属性名称"
                            prop="attrName">
                        </el-table-column>
                        <el-table-column
                            width="150"
                            label="属性参数">
                            <template slot-scope="scope">
                                <el-input
                                    v-if="scope.row.type === 'String' || scope.row.type === 'Raw'"
                                    type="textarea" v-model="scope.row.value" placeholder=""
                                    size="mini"></el-input>
                                <el-input-number v-if="scope.row.type === 'Integer'" controls-position="right"
                                                 v-model="scope.row.value" :min="scope.row.min"
                                                 :step="scope.row.step" :max="scope.row.max"
                                                 size="mini" step-strictly></el-input-number>
                                <el-input-number v-if="scope.row.type === 'Float'" controls-position="right"
                                                 v-model="scope.row.value" :min="scope.row.min"
                                                 :step="scope.row.step" :max="scope.row.max"
                                                 :precision="7" size="mini"
                                                 step-strictly></el-input-number>
                                <el-select v-if="scope.row.type === 'Boolean'"
                                           :popper-append-to-body="false"
                                           v-model="scope.row.value" placeholder="请选择" size="mini">
                                    <el-option label="true" :value="true"></el-option>
                                    <el-option label="false" :value="false"></el-option>
                                </el-select>
                                <el-select
                                    v-if="scope.row.type === 'Enum' || scope.row.type === 'Fault'"
                                    :popper-append-to-body="false"
                                    v-model="scope.row.value" placeholder="请选择" size="mini">
                                    <el-option
                                        v-for="item1 in scope.row.list"
                                        :key="item1.id"
                                        :label="item1.name"
                                        :value="item1.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="110"
                            label="操作">
                            <template slot-scope="scope">
                                <el-button :disabled="scope.row.authority === 1 || !isConnect" type="text" size="small"
                                           :loading="scope.row.getLoading"
                                           @click="getAttr(scope)">获取
                                </el-button>
                                <el-button :disabled="scope.row.authority === 2 || !isConnect" type="text" size="small"
                                           :loading="scope.row.setLoading"
                                           @click="setAttr(scope)">设置
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <!-- 属性表 end -->

                <el-col :span="1"
                        :style="{height: height + 'px',textAlign: 'center',lineHeight: height + 'px'}">
                    <i class="el-icon-sort" style="transform: rotate(90deg);font-size: 20px;"></i>
                </el-col>

                <!-- 日志表 start -->
                <el-col :span="15" class="col" :style="{height: height + 'px'}">
                    <div class="header_btn">
                        <div class="title">实时日志</div>
                        <el-button class="btn" size="small" type="text" @click="cleanLogList">清空
                        </el-button>
                    </div>
                    <el-divider></el-divider>
                    <el-table
                        ref="attributeTable"
                        class="attribute_table"
                        :data="logList"
                        :height="height - 73"
                        :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                        :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                        style="width: 100%;">
                        <el-table-column
                            width="200"
                            label="日志类型"
                            prop="type">
                        </el-table-column>
                        <el-table-column
                            label="内容"
                            prop="content">
                        </el-table-column>
                        <el-table-column
                            label="时间"
                            prop="time">
                        </el-table-column>
                    </el-table>
                </el-col>
                <!-- 日志表 end -->

            </el-row>
        </el-card>
        <!-- 卡片 end -->
    </div>
</template>

<script>
import adminHeader from '@/views/main/components/adminHeader';
import {mapState, mapActions} from "vuex";
import MQTT from "../../utils/mqtt";

export default {

    components: {
        adminHeader
    },

    computed: {
        ...mapState('login', ['userMsg'])
    },

    name: "deviceDebug",

    data() {
        return {

            //表格高度
            height: 100,

            //设备ID
            deviceId: 1,

            //设备列表
            deviceList: [],

            //属性列表
            attributeList: [],

            //服务key列表
            serviceKeyList: [],

            //日志数据
            logList: [],

            //设备是否在线
            isConnect: false,

            //mqtt对象
            mqtt: null,

            //是否是初始化mqtt
            isFirst: true
        }
    },

    destroyed() {
        window.removeEveshiistener('resize', this.setHeight)
        this.mqtt.closeMQTT();
    },

    mounted() {

        //兼容浏览器高度
        setTimeout(() => {
            this.height = (window.innerHeight - 45 - 153);
            let width = document.getElementsByClassName('col')[0].getElementsByClassName('header_btn')[0].offsetWidth;
            console.log(width, document.getElementsByClassName('el-dropdown-menu')[0])
            document.getElementsByClassName('el-dropdown-menu')[2].style.width = (width - 16) + "px";
        }, 100);
        window.addEventListener('resize', this.setHeight);

        //获取当前设备id
        this.deviceId = window.localStorage.getItem('deviceId');
        //初始化mqtt
        this.initMQTT();
        //获取设备在线状态
        this.isConnect = this.$route.query.isOnline === "Y";
        //获取虚拟设备列表
        this.queryDevice({
            currPage: 1,
            pageSize: 10000,
            type: 1,
            productCode: this.$route.query.id
        }).then(res => {
            this.deviceList = res.result.list;
        }, err => {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
        })
        //获取功能属性信息
        this.getMsg();
    },

    methods: {

        ...mapActions('product', ['queryDevice', 'getDeviceShadowToDebug', 'setDeviceShadowToDebug']),
        ...mapActions('effect', ['queryProductServiceList']),

        /**
         * 设置高度
         */
        setHeight() {
            this.height = (window.innerHeight - 45 - 153);
        },

        /**
         * 初始化mqtt
         */
        initMQTT() {
            if (this.$route.query.secret) {
                this.mqtt = new MQTT({
                    deviceSecret: this.$route.query.secret,
                    mac: this.$route.query.mac,
                    pid: this.$route.query.pid,
                    clientId: 'M-A-' + this.userMsg.userId
                }, (isSuccess, msg) => {
                    this.logList.unshift({
                        time: this.$config.format(new Date().getTime(), true),
                        type: "连接信息",
                        content: isSuccess ? "心跳发送成功" : "MQTT连接失败 => " + msg,
                    });
                    if (isSuccess && this.isFirst) {
                        this.isFirst = false;
                        this.mqtt.mqttReceive(this.onMQTTMsg, [
                            'b_rptattr/+/+/',
                            // 服务心跳主题
                            'heart/#',
                            'batch_rptattr/+/+/'
                        ])
                    }
                });
            }
        },

        /**
         * mqtt回调监听
         * @param isSendResponse
         * @param msg
         */
        onMQTTMsg(isSendResponse, msg) {
            this.loading = false;
            let time = isSendResponse ? this.$config.format(JSON.parse(msg).t, true) : this.$config.format(new Date().getTime(), true);
            this.logList.unshift({
                time: time,
                type: isSendResponse ? '设备响应' : '本地上报',
                content: msg
            });
            if (isSendResponse) this.setAttrMsg(msg);
            console.log(isSendResponse ? '响应 =》 ' : '上报结果 =》 ', msg);
        },

        /**
         * 设置回显收到的属性信息
         * @param msg
         */
        setAttrMsg(msg) {
            let data = JSON.parse(msg);
            data.attrs.forEach(item => {
                this.attributeList.forEach(item2 => {
                    if (item.k == item2.attrKey) {
                        item2.value = item.v
                    }
                })
            })
        },

        /**
         * 切换设备
         * @param command
         */
        handleCommand(command) {
            this.deviceId = command.deviceId;
            window.localStorage.setItem('deviceId', command.deviceId);
            this.$config.changeURLArg('secret', command.secret)
            this.$config.changeURLArg('mac', command.deviceName)
            this.$config.changeURLArg('deviceId', command.deviceId)
            this.$config.changeURLArg('isOnline', command.isOnline)
            this.$router.go(0);
        },

        /**
         * 清空日志信息
         */
        cleanLogList() {
            this.logList = [];
        },

        /**
         * 获取功能属性信息
         */
        getMsg() {
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (res.result && res.result.length > 0) this.getAttrList(res.result);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取并格式化属性信息
         * @param data
         */
        getAttrList(data) {
            //属性列表
            let attrList = [];
            //服务列表（数据是以属性方式返回所以说会有重复的服务key，所以用Set方式去重）
            let serviceList = new Set();

            console.log(data)
            for (let i = 0; i < data.length; i++) {
                if (data[i].productAttributeList && data[i].productAttributeList.length > 0) {
                    data[i].productAttributeList.forEach(item => {
                        let attr = {
                            //属性名称
                            attrName: item.name,
                            //属性Key
                            attrKey: item.attributeKey,
                            //属性格式
                            type: this.$config.getMsgItemUtil(this.$message.formatIDList, item.dataType, 'id', 'name'),
                            //属性内容
                            value: (item.dataType === 1 || item.dataType === 2) ? item.minIntValue ? parseInt(item.minIntValue) : undefined : "",
                            //最大值
                            max: item.maxIntValue >= 0 ? item.maxIntValue : undefined,
                            //最小值
                            min: item.minIntValue >= 0 ? item.minIntValue : undefined,
                            //步长
                            step: item.stepIntValue >= 0 ? item.stepIntValue : undefined,
                            //属性对应的服务key
                            serviceKey: data[i].serviceKey,
                            //设置按钮加载动画开关
                            setLoading: false,
                            //获取按钮加载动画开关
                            getLoading: false,
                            //属性权限
                            authority: item.authority
                        };
                        //将服务key加入数组
                        serviceList.add(data[i].serviceKey);
                        //属性要是为枚举或故障型时则遍历其数据列表
                        if (item.dataType === 3 || item.dataType === 7) {
                            let list = [];
                            let enumList = JSON.parse(item.enumValues);
                            for (let j = 0; j < enumList.length; j++) {
                                for (let key in enumList[j]) {
                                    list.push({
                                        id: key,
                                        name: enumList[j][key]
                                    })
                                }
                            }
                            attr.list = list;
                        }
                        attrList.push(attr)
                    })
                } else {
                    continue;
                }
            }
            this.attributeList = attrList;
            this.serviceKeyList = Array.from(serviceList);
            console.log(attrList);
        },

        /**
         * 获取设备影子中的属性数据
         * @param item
         */
        getAttr(item) {
            console.log(item);
            item.row.getLoading = true;
            this.getDeviceShadowToDebug({
                deviceId: this.deviceId,
                serviceKey: item.row.serviceKey,
                attributeKey: item.row.attrKey
            }).then(res => {
                item.row.value = res.result.attrs[0].v;
                this.logList.unshift({
                    type: "属性上报",
                    time: this.$config.format(res.result.t, true),
                    content: JSON.stringify(res.result)
                })
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                item.row.getLoading = false;
            })
        },

        /**
         * 设置（上报）属性数据
         * @param item
         */
        setAttr(item) {
            console.log(item)
            if (item.row.value !== 0 && !item.row.value) {
                this.$dialog.showMessage("请设置属性值", this.$config.TOAST_WARNING);
                return;
            }
            item.row.setLoading = true;
            let attrs = [{
                k: item.row.attrKey,
                v: item.row.value
            }];
            let data = {
                deviceId: this.deviceId,
                attrs: JSON.stringify(attrs),
                k: item.row.serviceKey
            }

            this.setDeviceShadowToDebug(data).then(res => {
                if (res.result === 'ok') {
                    this.logList.unshift({
                        type: "属性下发",
                        time: this.$config.format(new Date().getTime(), true),
                        content: JSON.stringify({
                            attrs: attrs,
                            k: item.row.serviceKey
                        })
                    })
                }
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                item.row.setLoading = false;
            })
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }
    }
}
</script>

<style scoped>
.el-card {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    margin: 64px 24px 24px 24px;
}

/deep/ .el-dropdown-menu__item {
    height: 36px;
}

/deep/ .el-dropdown {
    width: 100%;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-card__body {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

.main-container {
    background-color: #E8ECF0;
}

.main-container, .el-card {
    height: calc(100% - 30px);
}

.item {
    margin: 30px 0;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.col {
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 10px;
}


.header_btn {
    width: calc(100% - 16px);
    height: 30px;
    padding: 0 8px 5px 8px;
}

.device_id {
    font-size: 12px;
    color: #555555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.device_card {
    height: 38px;
    line-height: 38px;
    padding: 0 8px;
    margin: 0;
    width: 200px;
    background: rgba(0, 182, 0, 0.10);
    border-radius: 8px;
}

.spot {
    width: 10px;
    height: 10px;
    margin-top: 14px;
    border-radius: 15px;
    background-color: #52C41A;
    margin-left: 5px;
    float: right;
}

.state {
    font-weight: 400;
    font-size: 12px;
    color: #52C41A;
    float: right;
    margin-left: 5px;
}

.device_card_close {
    height: 38px;
    line-height: 38px;
    padding: 0 8px;
    margin: 0;
    background: #e0e2e5;
    border-radius: 8px;
}

.spot_close {
    width: 10px;
    height: 10px;
    margin-top: 14px;
    border-radius: 15px;
    background-color: #757575;
    margin-left: 5px;
    float: right;
}

.state_close {
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    float: right;
    margin-left: 5px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title {
    float: left;
    margin-top: 8px;
    font-weight: bold;
}

/deep/ .el-divider--horizontal {
    margin: 10px 0;
}

/deep/ .el-input-number {
    width: 100%;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #E1E1E1;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
